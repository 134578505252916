<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
  
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="name"
            label="Nombre"
            outlined
            hideDetails
          />
          <v-text-field
            style="margin-top: 20px;"
            v-model="description"
            label="Descripci�n"
            outlined
            hideDetails
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="onClose"
          >
            cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click="handleSave"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import apiBrightsign from '@/services/apiBrightsign'
export default {
  props: {
    device: {
      type: Object,
      default: null,
    },
    network: {
      type: String,
      default: null,
    },
    onClose: {
      type: Function,
      required: true,
    },
    onSync: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    name: null,
    description: null,
  }),
  watch: {
    device (v) {
      this.dialog = v ? true : false
      this.prepareData()
    },
    'device.name' () {
      this.prepareData()
    },
  },
  methods: {
    prepareData () {
      this.name = this.device.name
      this.description = this.device.description
    },
    handleSave () {
      const body = {
        network: this.network,
        oldName: this.device.name,
        newName: this.name,
        oldDescription: this.device.description,
        newDescription: this.description,
      }
      if(!this.name || !this.device.deviceID || this.name.length <= 3)
        return
      apiBrightsign.put(`/v1/private/devices/${this.device.deviceID}`, body)
        .then(() => {
          this.onSync(this.device.deviceID)
          this.onClose()
        })
        .catch(error => {
          console.log(error.response.status)
          console.log(error)
        })
    },
  },
}
</script>

