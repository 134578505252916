<template>
  <v-row 
    no-gutters
  >
    <v-col
      cols="12"
    >
    </v-col>
    <v-col
      cols="8"
      style="padding-right: 10px;"
    >
      <div style="background-color: white;">
        <v-data-table
          :headers="headers"
          :items="maps"
          hide-default-footer
          class="elevation-0"
        >
          <template v-slot:[`item.ident`]="{ item }">
            <a @click="handleEdit(item.ID)">{{ item.Name || item.ID }}</a>
          </template>
        </v-data-table>
      </div>
    </v-col>
    <v-col
      cols="4"
    >
      <map-form 
        v-if="config"
        :editedID="selectedID"
        :workspaceID="workspaceID"
        :locale="locale"
        :languages="config.languages"
        :onClose="handleClose"
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import apiDtouch from '@/services/apiDtouch'
import utils from '@/services/utils'
import MapForm from './MapForm'
export default {
  components: {
    MapForm,
  },
  data: () => ({
    selectedID: null,
    config: null,
    maps: [],
  }),
  computed: {
    ...mapState('app', ['locale']),
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
    headers () {
      return [
        {
          text: this.$t('dtouch.request.name', this.locale),
          align: 'start',
          value: 'ident',
        },
      ]
    },
  },
  mounted () {
    this.handleGetConfig()
    this.handleGetData()
  },
  methods: {
    handleGetConfig () {
      apiDtouch.getItem (`v2/private/workspaces/${this.workspaceID}`)
        .then(response => {
          if (!response) return
          this.config = {
            languages: response.Languages ? response.Languages.split(',') : []
          }
        })
    },
    handleGetData () {
      this.maps = []
      this.selectedID = null
      apiDtouch.getAll (`v2/private/workspaces/${this.workspaceID}/interactive-maps`)
        .then(response => {
          if (!response) return
          this.maps = response.map(item => {
            item.ident = {
              id: item.ID,
              name: item.Name
            }
            return item
          })
        })
    },
    handleEdit (v) {
      if (this.selectedID) {
        alert('Cierre la anterior edición')
        return
      }
      this.selectedID = v
    },
    handleClose (refresh) {
      if (refresh) this.handleGetData()
      this.selectedID = null
    },
  },
}
</script>


