<template>
  <v-row v-if="!editedItem">
    <v-col
      cols="12"
      sm="12"
      md="12"
      class="avai"
    >
      <v-data-table
        :headers="headers"
        :items="availabilities"
        :loading="loading"
        :itemsPerPage="10000"
        item-key="ID"
        class="page__table"
        hide-default-footer
        style="border: 1px solid #CCCCCC;"
      >
        <template v-slot:top>
          <v-toolbar dense elevation="0" style="border-bottom: 1px solid #CCCCCC">
            <v-toolbar-title>
              <v-icon>mdi-calendar-clock</v-icon>
              Temporalidad
            </v-toolbar-title>

            <v-spacer/>

            <v-btn
              @click="handleAdd"
              elevation="0"
              color="primary"
            >
              {{ $t('common.add', locale) }}
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.icon`]="{ item }">
          <a @click="() => { handleEdit(item.ID) }">
            <v-icon :color="getColorByAvailability(item)">mdi-calendar-month</v-icon>
          </a>
        </template>
        <template v-slot:[`item.ident`]="{ item }">
          <a @click="() => { handleEdit(item.ID) }">
            <div>
            {{ convertAvailability(item) }}
            </div>
          </a>
        </template>
        <!--template v-slot:body="props">

        </template-->
      </v-data-table>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="12"
    >
      <div
        style="font-style: italic;color: gray;border: 1px solid #CCCCCC; border-radius:10px;padding:10px;"
      >
        <p style="margin-bottom: 0;">
          <v-icon small color="#00ff00" style="margin-right: 10px;">mdi-calendar-clock</v-icon><small>Activo hoy y ahora</small>
        </p>
        <p style="margin-bottom: 0;">
          <v-icon small color="#96c8a2" style="margin-right: 10px;">mdi-calendar-clock</v-icon><small>Activo hoy pero no ahora</small>
        </p>
        <p style="margin-bottom: 0;">
          <v-icon small color="gray" style="margin-right: 10px;">mdi-calendar-clock</v-icon><small>Activo en futuros d�as</small>
        </p>
        <p style="margin-bottom: 0;">
          <v-icon small color="yellow" style="margin-right: 10px;">mdi-calendar-clock</v-icon><small>Activo en futuros d�as con <b>alternancia</b></small>
        </p>
        <p style="margin-bottom: 0;">
          <v-icon small color="red" style="margin-right: 10px;">mdi-calendar-clock</v-icon><small>Actividad pasada (fecha fin menor hoy)</small>
        </p>
      </div>
    </v-col>
  </v-row>
  <v-card v-else>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
          style="padding: 0 12px;"
        >
          <dialog-date-picker
            v-model="editedItem.DateStart"
            :locale="locale"
            :label="$t('availability.dateStart', locale)"
            :firstDayOfWeek="1"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          style="padding: 0 12px;"
        >
          <v-checkbox v-model="editedItem.NoEndDate" label="sin fecha fin." />
          <dialog-date-picker
            v-if="!editedItem.NoEndDate"
            v-model="editedItem.DateEnd"
            :locale="locale"
            :label="$t('availability.dateEnd', locale)"
            style="margin-bottom: 10px;"
            :firstDayOfWeek="1"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          style="padding: 0 12px;"
        >
          <v-btn-toggle
            v-model="editedItem.Type"
            color="primary"
            borderless
          >
            <v-btn value="alldays" x-small>
              {{ $t('availability.allDays', locale) }}
            </v-btn>
            <v-btn value="byweek" x-small>
              {{ $t('availability.byWeek', locale) }}
            </v-btn>

            <v-btn value="bymonth" x-small>
              {{ $t('availability.byMonth', locale) }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col
          v-if="editedItem.Type === 'byweek'"
          cols="12"
          sm="12"
          md="12"
          style="padding: 5px 0px;"
        >
          <div style="display: flex;flex-wrap: wrap;">
            <div
              v-for="item of week"
              :key="`m${item}`"
              style="padding: 2px;"
            >
              <v-btn
                @click="handleSelectWeek(item)"
                :color="editedItem.DaysWeek.indexOf(item) >= 0 ? 'primary' : null"
              >
                {{ $t(`common.s${item === 0 ? 6 : item -1}`, locale).substring(0, 3) }}
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col
          v-if="editedItem.Type === 'bymonth'"
          cols="12"
          sm="12"
          md="12"
          style="padding: 5px 0px;"
        >
          <div style="display: flex;flex-wrap: wrap;">
            <div
              v-for="item of month"
              :key="`m${item}`"
              style="padding: 2px;"
            >
              <v-btn
                @click="handleSelectMonth(item)"
                :color="editedItem.DaysMonth.indexOf(item) >= 0 ? 'primary' : null"
              >
                {{item}}
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          style="padding: 0 12px;"
        >
          <v-checkbox v-model="editedItem.AllTimes" label="a todas horas" />
        </v-col>
        <v-col
          v-if="!editedItem.AllTimes"
          cols="12"
          sm="12"
          md="6"
          style="padding: 0 12px;"
        >
          <small> {{$t('availability.timeStart', locale)}}: </small>
          <interval-times
            v-model="editedItem.TimeStart"
          />
        </v-col>
        <v-col
          v-if="!editedItem.AllTimes"
          cols="12"
          sm="12"
          md="6"
          style="padding: 0 12px;"
        >
          <small> {{$t('availability.timeEnd', locale)}}: </small>
          <interval-times
            v-model="editedItem.TimeEnd"
          />
        </v-col>
        <v-col
          v-if="editedItem.Type !== 'alldays'"
          cols="12"
          sm="12"
          md="6"
          style="padding: 0 12px;"
        >
          <small> {{$t('availability.alternation', locale)}}: </small>
          <v-select
            v-model="editedItem.Alt"
            :items="[1,2,3,4,5,6,7,8,9,10,11,12]"
            color="primary"
            required
            solo
            hide-details
            dense
          />
          <small style="font-style:italic;"> {{ prepareAltInfo(editedItem.Alt, editedItem.Type, locale) }} </small>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <delete-item
        v-if="!editedItem.tmpnew"
        :id="editedItem.ID"
        :onDeleteItem="handleDelete"
      />
      <v-btn
        @click="() => handleClose(false)"
      >
        {{ $t('common.cancel', locale) }}
      </v-btn>
      <v-btn
        color="primary"
        @click="handleSave"
      >
        {{ $t('common.save', locale) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
const defaultItem = {
  new: true,
  tmpnew: true,
  DateStart: null,
  DateEnd: null,
  NoEndDate: true,
  Type: 'alldays',
  DaysWeek: null,
  DaysMonth: null,
  AllTimes: 'false',
  TimeStart: 120,
  TimeEnd: 240,
  Alt: 1,
}
import utilsService from '@/services/utils'
import DeleteItem from '@/components/DeleteItem' 
import DialogDatePicker from '@/components/DialogDatePicker'
import IntervalTimes from '@/components/intervalTimes/Index'
import utilAvailability from './utilAvailability'
export default {
  components: {
    DeleteItem,
    DialogDatePicker,
    IntervalTimes,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    timezone: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function,
      requried: true,
    },
  },
  data: () => ({
    loading: false,
    availabilities: [],
    editedItem: null,
  }),
  computed: {
    headers () {
      return [
        { text: '', value: 'icon',  sortable: false, align: 'left', },
        { text: '', value: 'ident',  sortable: false, align: 'left', },
      ]
    },
    month () {
      const aux = []
      for (let i = 1; i <= 31; i++) aux.push(i)
      return aux
    },
    week () {
      const aux = []
      for (let i = 1; i < 7; i++) aux.push(i)
      aux.push(0)
      return aux
    },
  },
  watch: {
    id () {
      this.prepareData()
    },
    'editedItem.NoEndDate' (v) {
      if (!this.editedItem) return 
      if (v) this.editedItem.DateEnd = null
      else {
        if (!this.editedItem.DateEnd) this.editedItem.DateEnd = new Date().toISOString().split('T').shift()
      }
    },
    'editedItem.Type' (v) {
      if (v === 'byweek' && !this.editedItem.DaysWeek) {
        this.editedItem.DaysWeek = []
      }
      if (v === 'bymonth' && !this.editedItem.DaysMonth) {
        this.editedItem.DaysMonth = []
      }
    },
    'editedItem.TimeStart' (v, nv) {
      if (!nv) return
      this.editedItem.TimeEnd = v + 60
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareAltInfo (alt, type, locale) {
      if (!locale) return
      if (alt === 1) return `Se realiza ${type === 'bymonth' ? 'todos los meses.' : 'todas las semanas.'}`
      else return `Se realiza cada ${alt} ${type === 'bymonth' ? 'meses.' : 'semanas.'}`
    },
    getColorByAvailability (v) {
      const a = utilAvailability.checkLuxonAvailability(v, new Date().toISOString().split('T').shift(), this.timezone)
      if (a.date && a.time) return '#00ff00'
      if (a.old) return 'red'
      if (a.future && !a.date) return a.alt ? 'yellow' : 'gray'
      return a.date && !a.time ? '#96c8a2' : 'blue'
    },
    convertDate (v) {
      return !v && typeof v === 'string' ? v : v.toString().split('T').shift()
    }, 
    convertTime (v) {
      return utilsService.convertTime(v).split(' ').join('')
    },
    convertDayOfWeek (v) {
      return this.$t(`common.s${v === 0 ? 6 : v -1}`, this.locale).substring(0, 3).toLowerCase()
    },
    convertAvailability (v) {
      let result = `Desde ${this.convertDate(v.DateStart)} ${v.NoEndDate ? ' sin fin. ' : ` hasta ${this.convertDate(v.DateEnd)}. `} 
              ${v.AllTimes ? 'Todo el d�a.' : `De ${this.convertTime(v.TimeStart)} a ${this.convertTime(v.TimeEnd)}.`}
${v.Type === 'alldays' ? 'Todos los d�as.' : ` Los ${v.Type === 'byweek' ? v.DaysWeek.map(item => this.convertDayOfWeek(item)).join(', ') : v.DaysMonth.join(', ') }. `}
${v.Type !== 'alldays' && v.Alt > 1 ? `[Cada ${v.Alt} ${v.Type === 'bymonth' ? 'meses.' : 'semanas.'}]` : '' }
              `
      return result
    },
    sortAvailabilities (v) {
      if (!v) return null
      return v.sort((a, b) => {
        return new Date(this.convertDate(b.DateStart)) - new Date(this.convertDate(a.DateStart)) || b.ID - a.ID
      })
    },
    prepareData () {
      this.editedItem = null
      if (!this.id) return null
      const aux = this.value ? JSON.parse(JSON.stringify(this.value)) : null
      this.availabilities = this.sortAvailabilities(aux)
    },
    handleAdd () {
      const aux = JSON.parse(JSON.stringify(defaultItem))
      aux.ID = utilsService.getNewID()
      aux.DateStart = new Date().toISOString().split('T').shift()
      this.editedItem = aux
    },
    handleSelectWeek (v) {
      if (this.editedItem.DaysWeek.indexOf(v) >= 0) this.editedItem.DaysWeek = this.editedItem.DaysWeek.filter(x => x !== v)
      else this.editedItem.DaysWeek.push(v)
    },
    handleSelectMonth (v) {
      if (this.editedItem.DaysMonth.indexOf(v) >= 0) this.editedItem.DaysMonth = this.editedItem.DaysMonth.filter(x => x !== v)
      else this.editedItem.DaysMonth.push(v)
    },
    handleEdit (v) {
      const aux = JSON.parse(JSON.stringify(this.availabilities.filter(x => x.ID === v)))
      this.editedItem = aux.shift()
    },
    handleClose () {
      this.editedItem = null
    },
    handleDelete (v) {
      this.availabilities = this.availabilities.filter(x => x.ID !== v)
      this.onChange(this.availabilities)
      this.handleClose(true)
    },
    checkDate (v) {
      if (!v) return null
      
      if (typeof v === 'string') return v.split('T').shift()
      else return v.toISOString().split('T').shift()
    },
    handleSave () {
      if (this.editedItem.DateEnd && new Date(this.checkDate(this.editedItem.DateStart)) > new Date(this.checkDate(this.editedItem.DateEnd))) {
        alert('La fecha fin no puede ser anterior a la de inicio.')
        return
      }
      if (!this.editedItem.AllTimes && this.editedItem.TimeStart >= this.editedItem.TimeEnd) {
        alert('La hora de fin no puede ser anterior o igual a la de inicio.')
        return
      }
      const aux = this.availabilities.filter(x => x.ID !== this.editedItem.ID)

      this.editedItem.tmpnew = false

      if (this.editedItem.NoEndDate) this.editedItem.DateEnd = null

      this.editedItem.DateStart = this.checkDate(this.editedItem.DateStart)
      this.editedItem.DateEnd = this.checkDate(this.editedItem.DateEnd)

      if (this.editedItem.Type === 'alldays') {
        this.editedItem.DaysWeek = null
        this.editedItem.DaysMonth = null
      }
      if (this.editedItem.Type === 'byweek') {
        this.editedItem.DaysMonth = null
      }
      if (this.editedItem.Type === 'bymonth') {
        this.editedItem.DaysWeek = null
      }

      aux.unshift(this.editedItem)
      this.availabilities = this.sortAvailabilities(JSON.parse(JSON.stringify(aux)))
      this.onChange(this.availabilities)
      this.handleClose(true)
    },
  },
}
</script>
<style>
.avai .v-data-table-header {
  display: none;
}
.avai tr {
  background-color: #CCCCCC80 !important;
}
.avai tr:nth-child(2n) {
  background-color: #CCCCCC29 !important;
}
</style>
