<template>
  <v-row justify="center" v-if="templates">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          block
        >
          {{ $t('players.preview', locale) }}
        </v-btn>
      </template>
      <v-card
        style="background-color: #f7f7f7"
      >
        <v-toolbar
          dark
          color="primary"
          elevation="0"
        >
          <v-toolbar-title>
              {{ $t('players.preview', locale) }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
          </v-toolbar-items>
        </v-toolbar>
          <div
            v-if="template"
          >
            <div style="padding: 20px;">
            <center>
              <v-card
                elevation="2"
                class="main-template-preview" 
                :style="{ width: `${!isVertical ? width : getSize(1080)}px` }"
              >
                <div 
                  :style="{ backgroundColor: bgColor ? bgColor : template.PrevColor ? template.PrevColor : '#CCCCCC', width: `${!isVertical ? width : getSize(1080)}px`, height: `${!isVertical ? getSize(1080) : width}px`}"
                >
                  <zone 
                    v-for="zone of template.Zones"
                    :key="`${template.ID}${zone.ID}`"
                    :backgroundColor="prepareColor(zone.ID, zone.PrevColor)"
                    :color="prepareTextColor(zone.ID)"
                    :fontSize="`${prepareTextSize(zone.ID)}px`"
                    :top="`${getSize(zone.Top)}px`"
                    :right="`${getSize(zone.Right)}px`"
                    :width="template.ID === '1a458ce1-2349-4ee8-bb5e-3376eccbcfb8' && isVertical ? `${getSize(zone.Height)}px` : `${getSize(zone.Width)}px`"
                    :height="template.ID === '1a458ce1-2349-4ee8-bb5e-3376eccbcfb8' && isVertical ? `${getSize(zone.Width)}px` : `${getSize(zone.Height)}px`"
                    :originalWidth="template.ID === '1a458ce1-2349-4ee8-bb5e-3376eccbcfb8' && isVertical ? `${zone.Height}px` : `${zone.Width}px`"
                    :originalHeight="template.ID === '1a458ce1-2349-4ee8-bb5e-3376eccbcfb8' && isVertical ? `${zone.Width}px` : `${zone.Height}px`"
                    :scaleFactor="0.84"
                    :speedFactor="speedFactor"
                    :xmls="prepareXML(zone.ID)"
                    :zone="zone"
                  />
                </div>
              </v-card>
            </center>
            </div>
          <div class="text-center">
            <v-menu
              top
              close-on-content-click
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>
                    mdi-play-speed
                  </v-icon>
                  {{ getSpeedFactorName(speedFactor)}}
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, index) in speedFactors"
                  :key="index"
                  @click="speedFactor = item.id"
                >
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Zone from '@/views/private/players/presentations/previewMenu/Zone'
export default {
  components: {
    Zone,
  }, 
  props: {
    templates: {
      type: Array,
      default: null,
    },
    templateID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
    zones: {
      type: Array,
      required: true,
    },
    bgColor: {
      type: String,
      default: null,
    },
    isVertical: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: true,
    template: null,
    speedFactor: 1,
  }),
  computed: {
    width () {
      return 800
    },
    speedFactors () {
      return [
        {
          id: 0.5,
          name: 0.5,
        },
        {
          id: 0.75,
          name: 0.75,
        },
        {
          id: 1,
          name: 'normal',
        },
        {
          id: 1.25,
          name: 1.25,
        },
        {
          id: 1.5,
          name: 1.5,
        },
        {
          id: 1.75,
          name: 1.75,
        },
        {
          id: 2,
          name: 2,
        },
      ]
    },
  },
  watch: {
    templateID () {
      this.preparePreview ()
    },
    zones () {
      this.preparePreview()
    },
  },
  mounted () {
    this.preparePreview()
  },
  methods: {
    getSpeedFactorName (v) {
      return this.speedFactors.filter(x => x.id === v).map(item => item.name).shift()
    },
    getSize (v) {
      return (this.width * v) / 1920
    },
    preparePreview () {
      this.template = this.templates.filter(x => x.ID === this.templateID).shift()
    },
    prepareColor (id) {
      return this.zones.filter(x => x.Zone === id).map(item => `#${item.Color}`).shift()
    },
    prepareTextColor (id) {
      return this.zones.filter(x => x.Zone === id).map(item => `#${item.TextColor}`).shift()
    },
    prepareTextSize (id) {
      const aux = this.zones.filter(x => x.Zone === id).map(item => item.TextSize).shift()
      return aux * 0.7
    },
    prepareXML (id) {
      const aux = this.zones.filter(x => x.Zone === id).map(item => item.XML).shift()
      return aux ? aux.split(';') : []
    },
  },
}
</script>

