<template>
  <v-row>
    <v-col
      cols="12"
      sm="12"
      md="12"
    >
      <css-editor
        v-if="editedItem.ID"
        :onSave="handleChangeStyle"
        :config="{ id: editedItem.ID, template: 'show' }"
      />
      <small v-else style="color: red;">[GUARDADO REQUERIDO]: para personalizar la CSS primero debe estar guardado.</small>
    </v-col>
  </v-row>
</template>
<script>
import CssEditor from './cssEditor/Index'
export default {
  components: {
    CssEditor,
  },
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    editedItem: {
      type: Object,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleChangeStyle (v) {
      console.log('v', v)
    },
  },
}
</script>
