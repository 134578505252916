<template>
  <div :style="{ width: item.w, height: item.h}">
    <div ref="text" class="text" :style="{ lineHeight: `${item.h}px`,fontSize: fontsize, width: `${item.w}px`, height: `${item.h}px` }">{{item ? item.text : ''}}</div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    fontsize: 10,
  }),
  watch: {
    'item.text' () {
      this.prepareText()
    },
  },
  mounted () {
    this.prepareText()
  },
  methods: {
    isOverflown ({ clientHeight, scrollHeight }) {
      return scrollHeight > clientHeight
    },
    resizeText ({ element, elements, minSize = 10, maxSize = 512, step = 1, unit = 'px' }) {
      (elements || [element]).forEach(el => {
        let i = minSize
        let overflow = false

        const parent = el.parentNode
        let forceExit = 500

        while (!overflow && i < maxSize && forceExit) {
            el.style.fontSize = `${i}${unit}`
            overflow = this.isOverflown(parent)

          if (!overflow) i += step
          forceExit --
        }

        // revert to last state where no overflow happened
        el.style.fontSize = `${i - step}${unit}`
        this.fontsize = `${i - step}${unit}`
      })
    },
    prepareText () {
      this.resizeText({
        element: this.$refs.text,
        step: 0.25,
        maxSize: this.item.w,
      })

      /*
      if (!this.item || !this.item.text) return
      console.log(this.item, this.$refs.texthidden.offsetWidth, this.$refs.text)
      var size = this.item.h
      var desired_width = this.item.w
      var resizer = this.$refs.texthidden

      //resizer.innerHTML(this.item.text)
      let aux = 100

      while(resizer.offsetWidth > desired_width && aux) {
        console.log(resizer.offsetWidth, desired_width)
        size --
        //size = parseInt(resizer.style.fontSize, 10)
        console.log(size)
        resizer.style.fontSize = size//(size - 1)
        aux --
      }
      this.fontsize = size

      this.$refs.text.style.fontSize = size
      */
    },
  },
}
</script>
