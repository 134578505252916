<template>
  <v-row justify="center" v-if="downloadData">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800"
    >
      <v-card>
        <v-card-title class="text-h5">
        </v-card-title>
        <v-card-text>
          <v-row 
            no-gutters
            style="border-bottom: 1px solid #CCCCCC;margin-bottom: 20px;background-color: orange;padding-top:20px;"
          >
            <v-col
              cols="12"
              sm="2"
              style="padding: 0 0 10px 30px;"
            >
              <v-img
                :src="baIcon"
                style="width: 50px; height: auto;"
              />
            </v-col>
            <v-col
              cols="12"
              sm="8"
            >
              <p style="line-height:1;margin-bottom:0;">Name: <b>{{downloadData.Name}}</b></p>
              <p style="line-height:1;margin-bottom:0;">Type: <b>Presentation</b></p>
            </v-col>
            <v-col
              cols="12"
              sm="2"
              style="padding: 0 20px 10px 0;"
            >
              <v-btn
                v-if="downloadData && downloadData.ProjectName && downloadData.ProjectPath"
                color="primary"
                @click="handleClickDownload(downloadData.ProjectPath, downloadData.ProjectName)"
                small
                block
              >
                Descargar
              </v-btn>
            </v-col>
          </v-row>
          <v-row 
            no-gutters
            v-for="item of items"
            :key="item.ID"
            style="border-bottom: 1px solid #CCCCCC;margin-bottom:10px;"
          >
            <v-col
              cols="12"
              sm="2"
            >
              <v-icon
                v-if="item.Type === 'Audio'"
                x-large
                color="#CCCCCC"
                style="margin: 10px 25px;"
              >
                mdi-music
              </v-icon>
              <v-img
                v-else-if="item.ThumbPath"
                :src="item.ThumbPath"
                style="width: 100px; height: auto;"
              />
            </v-col>
            <v-col
              cols="12"
              sm="8"
              style="padding-bottom: 10px;"
            >
              <p style="line-height:1;margin-bottom:0;">Name: <b>{{item.Name}}</b></p>
              <p style="line-height:1;margin-bottom:0;">Type: <b>{{item.Type}}</b></p>
            </v-col>
            <v-col
              cols="12"
              sm="2"
              style="padding: 0 20px 10px 0;"
            >
              <v-btn
                color="primary"
                :href="item.Path"
                small
                block
              >
                Descargar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialog = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import apiBrightsign from '@/services/apiBrightsign'
import baIcon from '@/assets/baIcon.png'
export default {
  props: {
    downloadData: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
    loading: false,
    items: null,

  }),
  computed: {
    baIcon () {
      return baIcon
    },
  },
  watch: {
    downloadData () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      if (!this.downloadData) {
        this.dialog = false
        return
      }
      this.handleGetContent ()
    },
    handleGetContent () {
      this.items = null
      const url = `/v1/private/presentations/${this.downloadData.ID}/contents` 
      apiBrightsign.getAll(url)
        .then(response => {
          this.items = response
          this.loading = false
          this.dialog = true
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          this.dialog = true
        })
    },
    handleClickDownload (uri, name, type = 'text/xml') {
      apiBrightsign.put(`/v1/private/presentations/prepare-download`, {uri})
        .then(response => {
          const blob = new Blob([JSON.parse(response.data)], { type: type.toLowerCase() })

          // Create an object URL
          const url = URL.createObjectURL(blob)
          const anchor = document.createElement('a')
          anchor.href = url
          anchor.download = name

          // Append to the DOM
          document.body.appendChild(anchor)

          // Trigger `click` event
          anchor.click()

          // Remove element from DOM
          document.body.removeChild(anchor)
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
