<template>
  <div style="padding: 20px;background-color: #CCCCCC80;">
    <v-row>
      <v-col
        cols="12"
        xs="12"
        md="6"
      >
        <v-btn-toggle
          v-model="viewType"
          borderless
          color="white"
          background-color="primary"
          mandatory
          tile
        >
          <v-btn 
            value="manager" 

          >
            <span class="hidden-sm-and-down"
              style="color: gray;"
            >Modificar show</span>

            <v-icon right>
              mdi-ticket
            </v-icon>
          </v-btn>
          <v-btn 
            value="schedule"
          >
            <span class="hidden-sm-and-down"

              style="color: gray;"
            >Programar show</span>

            <v-icon right>
              mdi-calendar-plus
            </v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        md="2"
        style="text-align: right;"
      >
      </v-col>
      <v-col
        cols="12"
        xs="12"
        md="2"
        style="text-align: right;"
      >
      </v-col>
      <v-col
        cols="12"
        xs="12"
        md="2"
        style="text-align: right;"
      >
      </v-col>
    </v-row>
    <div>
    <schedule-view
      v-if="viewType === 'schedule' && languages && timezone"
      :timezone="timezone"
      :languages="languages"
      :locale="locale"
      :workspaceID="workspaceID"
      :contentTypeID="contentTypeID"
    />

    <manager-view
      v-if="viewType === 'manager' && languages && timezone"
      :timezone="timezone"
      :languages="languages"
      :locale="locale"
      :workspaceID="workspaceID"
      :contentTypeID="contentTypeID"
    />
    </div>
  </div>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import ScheduleView from './Schedule'
import ManagerView from './Manager'
export default {
  components: {
    ScheduleView,
    ManagerView,
  },
  props: {
    contentTypeID: {
      type: String,
      required: true,
    },
    workspaceID: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    weekDay: null,
    selectedWeek: 0,
    editedItem: null,
    forceRefresh: null,
    viewType: 'schedule',
    languages: null,
    timezone: null,
  }),
  watch: {
    contentTypeDataID () {
      this.handleGetLanguages()
      this.handlePrepareWeekDay()
    },
    selectedWeek () {
      this.handlePrepareWeekDay()
    },
  },
  mounted () {
    this.handleGetLanguages()
    this.handlePrepareWeekDay()
  },
  methods: {
    async handleGetLanguages () {
      const workspace = await new Promise((resolve, reject) => api.getItem ('dviewcontent', 'v1/public/workspaces/', this.workspaceID)
          .then(response => {   
          resolve({ languages: response.Languages ? JSON.parse(response.Languages) : [], timezone: response.Timezone ? response.Timezone : 'Europe/London' })
          })
        .catch (err => {
          reject(err)
        }))
      this.timezone = workspace.timezone
      this.languages = workspace.languages
    },
    getTranslation(v, locale) {
      return utils.getTranslation(v, locale)
    },
    getRange (v) {
      let ini = v[0].date.split('-')
      ini.shift()
      let end = v[6].date.split('-')
      end.shift()
      return `del ${ini.join('/')} al ${end.join('/')}`
    },
    addDays (v, daysToAdd) {
      return new Date(new Date(v).setDate(new Date(v).getDate() + daysToAdd))
    },
    handlePrepareWeekDay () {
      const result = []
      const today = this.selectedWeek ? this.addDays(new Date(), this.selectedWeek * 7) : new Date()
      let actualDay = ((today.getDay() === 0 ? 6 : today.getDay()))*-1
      for (let i = 0; i < 7; i++) {
        actualDay++
        result.push({
          contentTypeID: this.contentTypeID,
          i,
          date: this.addDays(today, actualDay).toISOString().split('T').shift(),
          old: this.selectedWeek < 0 || (this.selectedWeek === 0 && actualDay < 0)
        })
      }
      this.weekDay = result
    },
    handleCloseForm (refresh) {
      if (refresh) this.forceRefresh = new Date().toISOString()
      this.editedItem = null
    },
    /*
    handleAdd (v) {
      this.editedItem = {
        //CategoryID: this.contentTypeData.Categories[0].ID,
        Title: { es: '' },
        Description: { es: '' },
        Place: { es: ''},
        Time: 120,
        TimeEnd: 180,
        date: v,
        lmu: new Date().toISOString()
      }
    },
    */
  },
}
</script>
