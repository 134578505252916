<template>
  <div style="padding:10px;">
    <v-btn
      @click="onBack"
      color="primary"
      style="margin: 10px;"
    >
      volver
    </v-btn>
    <css-editor
      v-if="id"
      :config="prepareCssEditor(id)"
      :onSave="prepareURL"
    />
    <v-row no-gutters>
      <!--v-col
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
          <v-select
            v-model="typeID"
            :items="types"
            outlined
            label="Origen datos"
            itemText="name"
            itemValue="id"
            hide-details
            @change="prepareURL"
          />
      </v-col-->
      <v-col
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
        <id-selector
          :label="'ID'"
          :type="typeID === 'dview' ? 'bookingID' : typeID === 'id' ? 'dviewcontentOldID' : typeID"
          :value="id"
          :onChange="(v) => {
            id = v
            prepareURL()
          }"
        />
        <!--v-text-field
          v-model="id"
          label="ID"
          @input="prepareURL"
          hide-details
          outlined
        /-->
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
          <v-autocomplete
            v-model="header"
            :items="headers"
            outlined
            chips
            small-chips
            label="Cabecera"
            itemText="name"
            itemValue="id"
            hide-details
            @change="prepareURL"
          />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
          <v-autocomplete
            v-model="locale"
            :items="languages"
            outlined
            chips
            small-chips
            label="Idioma"
            itemText="NormalName"
            itemValue="ID"
            hide-details
            @change="prepareURL"
          />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="addDays"
          label="A�adir d�a (0-hoy, 1-ma�ana)"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="zoom"
          label="Zoom"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        style="text-align:center;padding:20px;"
      >
        <copy-url :url="url" />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="3"
      />
      <v-col
        cols="12"
        sm="12"
        lg="6"
        style="text-align: center !important;"
        class="preview"
      >
          <preview 
            v-if="parentWidth && url"
            :url="url"
            locale="es"
            :size="previewSize"
            :size_="{
              w: 1920,
              h: 1080
            }"
            :parentWidth="parentWidth"
          />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="3"
      />
    </v-row>
  </div>
</template>
<script>
import utilsService from '@/services/utils'
import IdSelector from './IdSelector'
import Preview from './Preview'
import CopyUrl from './CopyUrl'
import CssEditor from './cssEditor/Index'
export default {
  components: {
    IdSelector,
    Preview,
    CopyUrl,
    CssEditor,
  },
  props: {
    onBack: {
      type: Function,
      requried: true,
    },
  },
  data: () => ({
    previewSize: {
      w: 1920,
      h: 1080,
    },
    typeID: 'cmdviewcontent',
    url: null,
    zoom: '47.8125',
    header: null,
    id: null,
    locale: 'es',
    addDays: 0,
    parentWidth: null,
  }),
  computed: {
    headers () {
      const result = [{ id: null, name: ''}]
      const hc = [1, 2, 3, 4, 5]
      result.push(...hc.map(item => {
        return {
          id: `HC${item}`,
          name: `Hotel Cristina ${item}`,
        }
      }))
      return result
    },
    types () {
      return [
        {
          id: 'cmdviewcontent',
          name: 'NEW Dviewcontent (contentmananger)'
        },
      ]
    },
    languages () {
      return utilsService.getAllLanguages()
    },
  },
  watch: {
    typeID () {
      this.id = null
      this.prepareURL()
    },
  },
  mounted () {
    setTimeout(() => {
      this.parentWidth = document.getElementsByClassName('preview')[0].offsetWidth - 0
    }, 1000)
    this.prepareURL()
  },
  methods: {
    handleChangeBGColor (v) {
      this.bgColor = v
      this.prepareURL()
    },
    handleChangeColor1 (v) {
      this.color1 = v
      this.prepareURL()
    },
    handleChangeColor2 (v) {
      this.color2 = v
      this.prepareURL()
    },
    handleChangeColor3 (v) {
      this.color3 = v
      this.prepareURL()
    },
    handleChangeColor5 (v) {
      this.color5 = v
      this.prepareURL()
    },
    handleChangeColorTitle (v) {
      this.colorTitle = v
      this.prepareURL()
    },
    prepareURL () {
      if (!this.id) {
        this.url = null
        return
      }
      this.id = this.id.split(' ').pop('')
      this.url = `https://widgets.dviewcontent.com/meetingRoom/?`
        .concat(this.zoom && this.zoom.length > 0 && this.zoom !== '100' ? `&zoom=${this.zoom}`: '')
        .concat(this.header && this.header.length > 0 ? `&header=${this.header}`: '')
        .concat(`&addDays=${this.addDays ? this.addDays : 0}`)
        .concat(`&id=${this.id}`)
        .concat(`&locale=${this.locale}`)
        .concat(`&tmp=${new Date().toISOString().split('.').join('').split(':').join('')}`)
    },
    prepareCssEditor (v) {
      if (!v) return
      const config = {
        id: this.id,
        type: this.typeID,
        template: 'meeting-room',
        previewURL: this.url,
      }
      return config
    },
  },
}
</script>

