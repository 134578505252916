<template>
  <div v-if="zones" style="padding-bottom: 20px;">
            <center>
      <p style="line-height:1;">
        <v-icon small style="margin-right: 10px;" color="red">mdi-alert</v-icon>
        <small style="color: orange;"><b>
          Esto es un PROTOTIPO y se encuentra en DESARROLLO !!! <br/> [ uso ORIENTATIVO ]
        </b></small></p>
              <v-card
                elevation="2"
                class="main-template-preview" 
                :style="{ width: `${!isVertical ? width : getSize(presentationHeight)}px` }"
              >
                <div 
                  :style="{ backgroundColor: '#CCCCCC80', width: `${!isVertical ? width : getSize(presentationHeight)}px`, height: `${!isVertical ? getSize(presentationHeight) : width}px`}"
                >
                  <zone 
                    v-for="(zone, index) of zones"
                    :key="`${item.ID}${zone.ID}`"
                    :backgroundColor="prepareColor(zone.ID, zone.PrevColor)"
                    :color="prepareTextColor(zone.ID)"
                    :fontSize="`${prepareTextSize(zone.ID)}px`"
                    :top="`${getSize(zone.Top)}px`"
                    :right="`${getSize(zone.Right)}px`"
                    :width="isVertical ? `${getSize(zone.Height)}px` : `${getSize(zone.Width)}px`"
                    :height="isVertical ? `${getSize(zone.Width)}px` : `${getSize(zone.Height)}px`"
                    :originalWidth="isVertical ? `${zone.Height}px` : `${zone.Width}px`"
                    :originalHeight="isVertical ? `${zone.Width}px` : `${zone.Height}px`"
                    :scaleFactor="0.84"
                    :speedFactor="speedFactor"
                    :xmls="prepareXML(zone.ID)"
                    :zone="zone"
                    :style="{ 
                              border: showInfo ? '1px solid red' : null,
                              'z-index': index 
                            }"
                    :showInfo="showInfo"
                  />
                </div>
              </v-card>
            </center>
  </div>
</template>
<script>
import apiBrightsign from '@/services/apiBrightsign'
import Zone from './Zone'
export default {
  components: {
    Zone,
  }, 
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    zones: null,
    isVertical: false,
    speedFactor: 1,
    showInfo: false,
    presentationWidth: null,
    presentationHeight: null,
  }),
  computed: {
    isDev () {
      return process.env.VUE_APP_ENV === 'development' ? true : false
    },
    width () {
      return 800
    },
    speedFactors () {
      return [
        {
          id: 0.5,
          name: 0.5,
        },
        {
          id: 0.75,
          name: 0.75,
        },
        {
          id: 1,
          name: 'normal',
        },
        {
          id: 1.25,
          name: 1.25,
        },
        {
          id: 1.5,
          name: 1.5,
        },
        {
          id: 1.75,
          name: 1.75,
        },
        {
          id: 2,
          name: 2,
        },
      ]
    },
  },
  watch: {
    'item.ID' () {
      this.handleGetPreviewData()
    },
  },
  mounted () {
    this.handleGetPreviewData()
  },
  methods: {
    handleGetPreviewData () {
      this.loading = true
    this.zones = null
    this.isVertical = false
      const url =  `/v1/private/presentations/${this.item.ID}/preview-data`
      apiBrightsign.getAll(url)
        .then(response => {
          /*
          response.map(item => {
            console.log('zones', item.Name, item.XML)
            return item
          })*/
          this.isVertical = response.map(item => item.Vertical).shift() ? true : false
          const pSize = response.map(item => item.PresentationSize).shift()

          this.presentationWidth = pSize && pSize.width ? pSize.width : 1920
          this.presentationHeight = pSize && pSize.height ? pSize.height : 1080

          //todo is vertical remove IMAGEN VERTICAL (screenshot)
          if (this.isVertical) {
            response = response.filter(x => x.Name !== 'IMAGEN VERTICAL')
          }
          
          const aux = []
          aux.push(...response.filter(x => x.SendToBack).sort((a,b) => {
            return a.ORD-b.ORD
          }))
          response = response.filter(x => !x.SendToBack)
          aux.push(...response.filter(x => !x.IsHTML).sort((a,b) => {
            return a.ORD-b.ORD
          }))
          aux.push(...response.filter(x => x.IsHTML).sort((a,b) => {
            return a.ORD-b.ORD
          }))
          this.zones = aux
          //console.log(this.zones.map(item => {return { height: item.Height,color: item.Color ? item.Color : 'transparent',name: item.Name, sb: item.SendToBack, h: item.IsHTML}}))
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    },
    getSpeedFactorName (v) {
      return this.speedFactors.filter(x => x.id === v).map(item => item.name).shift()
    },
    getSize (v) {
      return (this.width * v) / this.presentationWidth//1920
    },
    preparePreview () {
      this.template = this.templates.filter(x => x.ID === this.templateID).shift()
    },
    prepareColor (id) {
      return this.zones.filter(x => x.Zone === id).map(item => item.Color ? `#${item.Color}` : 'transparent').shift()
    },
    prepareTextColor (id) {
      return this.zones.filter(x => x.Zone === id).map(item => item.TextColor ? `#${item.TextColor}` : 'transparent').shift()
    },
    prepareTextSize (id) {
      const aux = this.zones.filter(x => x.Zone === id).map(item => item.TextSize).shift()
      return this.getSize(aux) * 0.7
    },
    prepareXML (id) {
      const aux = this.zones.filter(x => x.Zone === id).map(item => item.XML).shift()
      return aux ? aux.split(';') : []
    },
  },
}
</script>
