<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('dtouch.interactiveMaps.title', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-row
        v-if="categories"
      >
        <v-col
          cols="12"
          sm="12"
        >
          <small style="color:orange;">Categorías:</small>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          v-for="(item) of categories"
          :key="item.ID"
          style="margin: 5px 0;border: 1px solid orange; background-color: #cccccc;"
        >
          <small style="color:orange;">{{getTranslation(item.Name, locale)}}:</small>
          <small style="color: red;" @click="handleDeleteItem(item.ID)"> [delete]</small>
          <form-item
            :editedItem="item"
            :locale="locale"
            :languages="languages"
            :onChange="handleChange"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="text-aling: center;"
        >
          <center><v-btn @click="handleAddItem" color="orange"> añadir categoría</v-btn></center>
        </v-col>
      </v-row>

      <br />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
const defaultItem = { Name: { es: '' }}
import api from '@/services/api'
import utils from '@/services/utils'
import FormItem from './FormItem'
export default {
  name: 'RequestsForm',
  components: {
    FormItem,
  },
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: 'es',
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    categories: null,
    workspaces: [],
    loading: true,

    editedItem: null,
    languages: ['es', 'en'],
  }),
  methods: {
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    getData () {
      api.getAll('dtouch', `v1/system/workspaces/${this.workspaceID}/form-interactive-maps`)
        .then(response => {
            this.categories = response.map(item => {
              return {
                ID: item.ID,
                Name: item.Name ? JSON.parse(item.Name) : { es: ''},
                Icon: item.Icon,
              }
            })
        })
    },
    handleExpand () {
      if(!this.categories && this.workspaceID) this.getData()
    },
    handleChange (v) {
      for (let i = 0; i < this.categories.length ; i++) {
        if (this.categories[i].ID === v.ID) this.categories[i] = v
      }
      this.onChangeData(this.categories)
    },
    handleAddItem () {
      const aux = JSON.parse(JSON.stringify(defaultItem))
      aux.ID = utils.getNewID()
      this.categories.push(aux)
      this.onChangeData(this.categories)
    },
    handleDeleteItem (v) {
      this.categories = this.categories.filter(x => x.ID !== v)
      this.onChangeData(this.categories)
    },
  },
}
</script>

