<template>
  <div>
    <v-btn
      style="text-transform: none;font-family:Roboto;font-weight:400;letter-spacing: 0;"
      dark
      color="red"
      small
      v-if="is404"
    >
      <v-icon x-small style="margin-right:5px;color:orange;">mdi-alert</v-icon>404 !
    </v-btn>
    <v-btn
      text 
      style="text-transform: none;font-family:Roboto;font-weight:400;letter-spacing: 0;"
      @click="handleGetURLInfo"
    >
      {{url}}
    </v-btn>
    <v-btn
      x-small
      color="gray"
      elevation="1"
      @click="handleCopy"
    >
      <v-icon x-small>mdi-content-copy</v-icon>
    </v-btn>
    <v-snackbar
      v-model="showAlert"
      timeout="1000"
    >
      Copiado!!!

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="showAlert = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="showInfo"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          URL Info
        </v-card-title>

        <v-card-text style="padding: 10px 50px;">
          <v-row
            v-if="info"
            no-gutters
          >
            <v-col
              v-for="(item, index) in info"
              :key="`${index}`"
              cols="12"
              sm="12"
            >
              <v-row
                v-if="item.value"
                no-gutters
              >
                <v-col
                  cols="12"
                  sm="3"
                >
                  <small style="color:#cccccc;">{{item.type.toUpperCase()}}:</small>
                </v-col>
                <v-col
                  cols="12"
                  sm="9"
                  :style="{ color: item.color ? item.color : 'gray' }"
                >
                  <small><b>{{item.value.toUpperCase()}}</b></small>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            text
            @click="showInfo = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
//import axios from 'axios'
import apiBrightsign from '@/services/apiBrightsign'
export default {
  props: {
    url: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    showAlert: false,
    showInfo: false,
    info: null,
    is404: false,
  }),
  watch: {
    url () {
      this.handleCheckIs404()
    },
  },
  mounted () {
    this.handleCheckIs404()
  },
  methods: {
    async handleCheckIs404 () {
      this.is404 = this.url.toLowerCase().indexOf('.xml') > 0 ? await new Promise((resolve) => fetch(this.url.replace('https://dviewcontent', 'https://www.dviewcontent'))
        .then(response => response.text())
        .then(() => resolve(false))
        .catch(() => resolve(true))
      ) : false
      /*
      fetch(this.url)
        .then((response) => {
          if (response.ok) {
            console.log('OK')
          } else {
            console.log("Respuesta de red OK pero respuesta HTTP no OK");
          }
        })
        .catch((error) => {
          this.is404 = true
          console.log("Hubo un problema con la petici�n Fetch:" + error.message);
        })
        */
      /*
      axios.get(this.url)
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          alert('error')
          console.log(error.response)
          this.is404 = true
        })*/
    },
    handleCopy () {
      if (!this.url) return
      this.copyTextToClipboard(this.url)
      this.showAlert = true
    },
    fallbackCopyTextToClipboard (text) {
      var textArea = document.createElement("textarea")
      textArea.value = text;
      
      // Avoid scrolling to bottom
      textArea.style.top = "0"
      textArea.style.left = "0"
      textArea.style.position = "fixed"

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        console.log('Fallback: Copying text command was ' + msg)
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err)
      }

      document.body.removeChild(textArea)
    },
    copyTextToClipboard (text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text)
        return;
      }
      navigator.clipboard.writeText(text).then(function() {
        console.log('Async: Copying to clipboard was successful!')
      }, function(err) {
        console.error('Async: Could not copy text: ', err)
      });
    },
    handleGetURLInfo () {
      apiBrightsign.put(`/v1/private/presentations/url-info`, { url: this.url })
        .then(response => {
          this.info = response.data
          this.showInfo = true
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

