<template>
  <v-card
    v-if="devices"
    class="mx-auto"
  >
    <v-card-text>
      <p class="text-h4 text--primary">
        Dispositivos
      </p>
      <v-row
        no-gutters
      >
        <v-col
          v-if="devices.length === 0"
          cols="12"
          sm="12"
          md="12"
          style="text-align: center"
        >
          <h3>No hay datos</h3>
        </v-col>
        <v-col
          v-for="(device, index) in devices"
          :key="`device-${index}`"
          cols="12"
          sm="12"
          md="12"
        >
          <v-card
            class="pa-2"
            outlined
            tile
          >
            <v-row
              no-gutters
            >
              <v-col
                cols="12"
                sm="4"
                md="4"
              >
                {{device.Serial}}
              </v-col>
              <v-col
                cols="12"
                sm="2"
                md="2"
              >
                {{device.Name}}
              </v-col>
              <v-col
                cols="12"
                sm="2"
                md="2"
              >
                <div 
                  @click="handleShowError(device.ID)" 
                  style="cursor: pointer;"
                  :style="{ color: device.Status === 'Error' ? 'red' : device.Status === 'Normal' ? 'green' : device.Status === 'Warning' ? 'orange' : 'gray' }"
                >
                  {{device.Status}}
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="2"
              >
                <p style="line-height: 1;margin-bottom:2px;color:#cccccc;">
                  <small>
                    Error: <b style="color:gray;">{{convertDatetime(device.LastErrorTime)}}</b>
                  </small>
                </p>
                <p style="line-height: 1;margin-bottom:2px;color:#cccccc;">
                  <small>
                    ContentCheck: <b style="color:gray;">{{convertDatetime(device.LastContentCheckTime)}}</b>
                  </small>
                </p>
                <p style="line-height: 1;margin-bottom:2px;color:#cccccc;">
                  <small>
                    Download: <b style="color:gray;">{{convertDatetime(device.LastContentDownloadEndTime)}}</b>
                  </small>
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    <error-info :errorList="errorList" :onClose="() => { errorList = null }"/>
    </v-card-text>
  </v-card>
</template>
<script>
import apiBrightsign from '@/services/apiBrightsign'
import ErrorInfo from './ErrorInfo'
export default {
  components: {
    ErrorInfo,
  },
  props: {
    network: {
      type: String,
      requried: true,
    },
    presentationID: {
      type: String,
      requried: true,
    },
  },
  data: () => ({
    loading: false,
    devices: null,
    errorList: null,
  }),
  watch: {
    presentationID () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    convertDatetime (v) {
      return v ? v.split('T').join(' ').split('.').shift() : null
    },
    handleGetData () {
      this.groups = null
      if (!this.presentationID) return
      apiBrightsign.getAll(`/v1/private/presentations/${this.presentationID}/devices`)
        .then(response => {
          this.devices = response
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    },
    handleShowError(v) {
      this.errorList = null
      apiBrightsign.put(`/v1/private/devices/${this.network}/errors`, { deviceID: v})
        .then(response => {
          if (response.data && response.data.length > 0) {
            const aux = []
            const limit = response.data.length > 10 ? 10 : response.data.length
            for (let i = 0; i < limit; i++){
              aux.push(response.data[i])
            }
            this.errorList = aux
          } else {
            this.errorList = []
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

