<template>
  <div v-if="!isMe" style="text-align: center;padding: 100px; color: orange,">
    <h1>NO DISPONIBLE</h1>
  </div>
  <div v-else>
    <div style="text-align: right; padding: 20px 20px 0 0;" v-if="permissions">
      <last-sync-date v-if="typeList && network" :typeList="typeList" :networkID="network"/>
      <v-btn-toggle
        v-model="typeList"
        borderless
        mandatory
      >
        <v-btn value="presentations">
          <span class="hidden-sm-and-down">presentaciones</span>

          <!--v-icon right>
            mdi-format-align-left
          </v-icon-->
        </v-btn>

        <v-btn value="groups">
          <span class="hidden-sm-and-down">grupos</span>

          <!--v-icon right>
            mdi-format-align-center
          </v-icon-->
        </v-btn>

        <v-btn value="devices">
          <span class="hidden-sm-and-down">Dispositivos</span>

          <!--v-icon right>
            mdi-format-align-center
          </v-icon-->
        </v-btn>
      </v-btn-toggle>
    </div>
    <list-presentation v-if="network && typeList === 'presentations'" :network="network"/>
    <list-group v-if="network && typeList === 'groups'" :network="network"/>
    <list-device v-if="network && typeList === 'devices'" :network="network" :permissions="permissions"/>
    <v-btn
      id="btnBackToTop"
      color="rgba(0, 0, 0, 0.3)"
      fixed
      fab
      dark
      bottom
      right
      @click.stop.prevent="handleBackToTop"
    >
      <v-icon>mdi-arrow-up-bold</v-icon>
    </v-btn>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import utilsService from '@/services/utils'
import ListPresentation from './list/ListPresentation.vue'
import ListGroup from './list/ListGroup.vue'
import ListDevice from './list/ListDevice.vue'
import LastSyncDate from './LastSyncDate'
export default {
  components: {
    ListPresentation,
    ListGroup,
    ListDevice,
    LastSyncDate,
  },
  data: () => ({
    network: null,
    permissions: null,
    typeList: 'presentations',
  }),
  computed: {
    ...mapState('app',['locale', 'playersWorkspace']),
    workspaceID () {
      return this.playersWorkspace
    },
    isMe () {
      if (process.env.VUE_APP_ENV === 'development' || utilsService.getUser() === 'fcc82081-173b-4679-bc3b-dc26c61f9f3c') return true
      else return false
    },
  },
  watch: {
    playersWorkspace () {
      this.handleGetNetwork()
    },
  },
  mounted () {
    this.handleGetPermissions()
    this.handleGetNetwork()
  },
  methods: {
    handleBackToTop () {
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    },
    handleGetPermissions () {
      this.permissions = null
      api.getAll('players', `v1/internal/users/permissions`)
        .then(response => {
          this.permissions = response
        })
        .catch(error => {
          console.log(error)
        })
    },
    handleGetNetwork () {
      this.network = null
      if (!this.workspaceID) return
      api.getItem('players', `v1/private/workspaces/`, this.workspaceID.concat('/network'))
        .then(response => {
          this.network = response
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

