<template>
  <div style="padding:10px;">
    <v-btn
      @click="onBack"
      color="primary"
      style="margin: 10px;"
    >
      volver
    </v-btn>
    <css-editor
      v-if="id"
      :config="prepareCssEditor(id)"
      :onSave="prepareURL"
    />
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
          <v-select
            v-model="typeID"
            :items="types"
            outlined
            label="Origen datos"
            itemText="name"
            itemValue="id"
            hide-details
            @change="prepareURL"
          />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
        <id-selector
          :label="'ID'"
          :type="typeID === 'dview' ? 'bookingID' : typeID === 'id' ? 'dviewcontentOldID' : typeID"
          :value="id"
          :onChange="(v) => {
            id = v
            prepareURL()
          }"
        />
        <!--v-text-field
          v-model="id"
          label="ID"
          @input="prepareURL"
          hide-details
          outlined
        /-->
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
          <v-select
            v-model="typeView"
            :items="typeViews"
            outlined
            label="Vista"
            itemText="name"
            itemValue="id"
            hide-details
            @change="prepareURL"
          />
      </v-col>
      <v-col
        v-if="typeView === 'banner'"
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
          <v-checkbox
            v-model="isShow"
            label="Shows"
            hide-details
            @change="prepareURL"
          />
      </v-col>
      <v-col
        v-if="['hotel', 'dview'].indexOf(typeID) >= 0"
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
        <v-select
          v-model="folder"
          :items="folders"
          outlined
          label="Im�genes (acttiv)"
          itemText="name"
          itemValue="id"
          hide-details
          @change="prepareURL"
        />
      </v-col>
      <!--v-col
        v-if="['hotel', 'dview'].indexOf(typeID) >= 0"
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="folder"
          label="Im�genes (acttiv)"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col-->
      <v-col
        v-if="['cmdviewcontent'].indexOf(typeID) >= 0"
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
        <content-type-id-selector
          v-if="id"
          label="ID tipo contenido (xxxx-xxx-xxxx)"
          :workspaceID="id"
          :value="contentType"
          :onChange="(v) => {
            contentType = v
            prepareURL()
          }"
        />
        <div v-else style="text-align:center;color: orange;">
          INFO: ID not found!!!
        </div>
        <!--v-text-field
          v-model="contentType"
          label="ID tipo contenido (xxxx-xxx-xxxx)"
          @input="prepareURL"
          hide-details
          outlined
        /-->
        <small style="font-style: italic;color: #CCCCCC;">EJ: para mostrar solo los shows</small>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="category"
          label="CategoryID"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
          <v-autocomplete
            v-model="locale"
            :items="languages"
            outlined
            chips
            small-chips
            label="Idioma"
            itemText="NormalName"
            itemValue="ID"
            hide-details
            @change="prepareURL"
          />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="addDays"
          label="A�adir d�a (0-hoy, 1-ma�ana)"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="zoom"
          label="Zoom"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
        <color-picker-item
          :value="color1"
          label="Color 1 (t�tulo act.)"
          :onChange="handleChangeColor1"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
        <color-picker-item
          :value="color2"
          label="Color 2 (Hora y d�a)"
          :onChange="handleChangeColor2"
        />
      </v-col>
      <!--v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <color-picker-item
          :value="color3"
          label="Color 3"
          :onChange="handleChangeColor3"
        />
      </v-col-->
      <v-col
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
        <color-picker-item
          :value="color5"
          label="Color Fondo"
          :onChange="handleChangeColor5"
        />
      </v-col>
      <v-col
        v-if="typeView === 'banner'"
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
        <color-picker-item
          :value="colorTitle"
          label="Color T�tulo"
          :onChange="handleChangeColorTitle"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="mt"
          label="Margen Superior"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="ml"
          label="Margen izquierdo"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        v-if="['banner'].indexOf(typeView) >= 0"
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="rows"
          label="Filas"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        v-if="['row'].indexOf(typeView) >= 0"
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="cols"
          label="Columnas"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="width"
          label="Forzar ancho"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="height"
          label="Forzar alto"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>

      <v-col
        cols="12"
        sm="12"
        style="text-align:center;padding:20px;"
      >
        <copy-url :url="url" />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="3"
      />
      <v-col
        cols="12"
        sm="12"
        lg="6"
        style="text-align: center !important;"
        class="preview"
      >
          <preview 
            v-if="parentWidth && url"
            :url="url"
            locale="es"
            :size="previewSize"
            :size_="{
              w: 1920,
              h: 1080
            }"
            :parentWidth="parentWidth"
          />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="3"
      />
    </v-row>
  </div>
</template>
<script>
import utilsService from '@/services/utils'
import ColorPickerItem from '@/components/common-components/ColorPickerItem'
import IdSelector from './IdSelector'
import ContentTypeIdSelector from './ContentTypeIdSelector'
import Preview from './Preview'
import CopyUrl from './CopyUrl'
import CssEditor from './cssEditor/Index'
export default {
  components: {
    IdSelector,
    ContentTypeIdSelector,
    Preview,
    CopyUrl,
    ColorPickerItem,
    CssEditor,
  },
  props: {
    onBack: {
      type: Function,
      requried: true,
    },
  },
  data: () => ({
    previewSize: {
      w: 1920,
      h: 1080,
    },
    //typeID: 'hotel',
    typeID: 'cmdviewcontent',
    url: null,
    zoom: 100,
    //id: 350,//909c0748-f9b0-4e07-90ea-4860ebdd558b',
    id: null,
    typeView: null,
    isShow: false,
    locale: 'es',
    bgColor: null,
    color1: null,
    color2: null,
    color3: null,
    color5: null,
    colorTitle: null,
    folder: 'acttiv',
    addDays: 0,
    category: null,
    mt: 0,
    ml: 0,
    parentWidth: null,
    rows: null,
    cols: null,
    width: null,
    height: null,

    contentType: null, // used to filter cmdviewcontent activity (dviewcontent NEW)
  }),
  computed: {
    types () {
      return [
        {
          id: 'hotel',
          name: 'Acttiv'
        },
        {
          id: 'dview',
          name: 'BookingID'
        },
        {
          id: 'id',
          name: 'ACT. OLD Dviewcontent'
        },
        {
          id: 'showID',
          name: 'Show. OLD Dviewcontent'
        },
        {
          id: 'cmdviewcontent',
          name: 'NEW Dviewcontent (contentmananger)'
        },
      ]
    },
    typeViews () {
      return [
        {
          id: null,
          name: 'Tarjetas',
        },
        {
          id: 'row',
          name: 'filas'
        },
        {
          id: 'banner',
          name: 'Banner paginado'
        },
        {
          id: 'bannerscroll',
          name: 'Banner Scroll',
        },
      ]
    },
    folders () {
      return [
        { id: 'genericas', name: 'genericas' }, 
        { id: 'barcelonew', name: 'barcelonew' }, 
        { id: 'acttiv', name: 'acttiv' }
      ]
    },
    languages () {
      return utilsService.getAllLanguages()
    },
  },
  watch: {
    isShow () {
      if (this.isShow) this.rows = '1'
      this.prepareURL()
    },
    typeID () {
      this.id = null
      this.prepareURL()
    },
    typeView (v) {
      if (v !== 'banner' && this.isShow) this.isShow = false

      if (['banner'].indexOf(this.typeView) >= 0) {
        if (!this.rows) {
          this.rows = '6'
          this.prepareURL()
        }
      } else {
        if (this.rows) {
          this.rows = null
          this.prepareURL()
        }
      }
      if (['row'].indexOf(this.typeView) >= 0) {
        if (!this.cols) {
          this.cols = '2'
          this.prepareURL()
        }
      } else {
        if (this.cols) {
          this.cols = null
          this.prepareURL()
        }
      }
      if (['banner', 'bannerscroll'].indexOf(this.typeView) >= 0) {
        this.previewSize = {
          w: 480,
          h: 825,
        }

        if (!this.width) {
          this.width = '480'
          this.prepareURL()
        }
        if (!this.height) {
          this.height = '825'
          this.prepareURL()
        }
      } else {
        this.previewSize = {
          w: 1920,
          h: 1080,
        }
        if (this.width) {
          this.width = null
          this.prepareURL()
        }
        if (this.height) {
          this.height = null
          this.prepareURL()
        }
      }
    },
  },
  mounted () {
    setTimeout(() => {
      this.parentWidth = document.getElementsByClassName('preview')[0].offsetWidth - 0
    }, 1000)
    this.prepareURL()
  },
  methods: {
    handleChangeBGColor (v) {
      this.bgColor = v
      this.prepareURL()
    },
    handleChangeColor1 (v) {
      this.color1 = v
      this.prepareURL()
    },
    handleChangeColor2 (v) {
      this.color2 = v
      this.prepareURL()
    },
    handleChangeColor3 (v) {
      this.color3 = v
      this.prepareURL()
    },
    handleChangeColor5 (v) {
      this.color5 = v
      this.prepareURL()
    },
    handleChangeColorTitle (v) {
      this.colorTitle = v
      this.prepareURL()
    },
    prepareURL () {
      if (!this.id) {
        this.url = null
        return
      }
      this.id = this.id.split(' ').pop('')
      //this.url = 'https://www.dviewcontent.com/activity/player/?zoom=50&addDays=0&c1=3C3C3B&c2=d12726&c5=ffffff00&ml=60&mt=-5&hotel=350&folder=barcelonew&locale=es'
      this.url = 'https://www.dviewcontent.com/activity/player/?'
        .concat(this.zoom && this.zoom.length > 0 && this.zoom !== '100' ? `&zoom=${this.zoom}`: '')
        .concat(`&addDays=${this.addDays ? this.addDays : 0}`)
        .concat(`&${this.typeID}=${this.id}`)
        .concat(`&locale=${this.locale}`)
        .concat(this.typeView ? `&type=${this.typeView}` : '')
        .concat(this.typeView && this.isShow ? `&show=true` : '')
        .concat(this.bgColor && this.bgColor.length > 0 ? `&bgcolor=${this.bgColor.replace('#','')}`: '')
        .concat(this.color1 && this.color1.length > 0 ? `&c1=${this.color1.replace('#','')}`: '')
        .concat(this.color2 && this.color2.length > 0 ? `&c2=${this.color2.replace('#','')}`: '')
        .concat(this.color3 && this.color3.length > 0 ? `&c3=${this.color3.replace('#','')}`: '')
        .concat(this.color5 && this.color5.length > 0 ? `&c5=${this.color5.replace('#','')}`: '')
        .concat(this.colorTitle && this.colorTitle.length > 0 ? `&colortitle=${this.colorTitle.replace('#','')}`: '')
        .concat(['hotel', 'dview'].indexOf(this.typeID) >= 0 && this.folder && this.folder.length > 0 ? `&folder=${this.folder}`: '')
        .concat(this.category && this.category.length > 0 ? `&category=${this.category}`: '')
        .concat(this.mt && this.mt.length > 0 && this.mt !== '0' ? `&mt=${this.mt}`: '')
        .concat(this.ml && this.ml.length > 0 && this.ml !== '0' ? `&ml=${this.ml}`: '')
        .concat(this.rows && this.rows.length > 0 ? `&rows=${this.rows}`: '')
        .concat(this.cols && this.cols.length > 0 ? `&cols=${this.cols}`: '')
        .concat(this.width && this.width.length > 0 ? `&w=${this.width}`: '')
        .concat(this.height && this.height.length > 0 ? `&h=${this.height}`: '')
        .concat(['cmdviewcontent'].indexOf(this.typeID) >= 0 && this.contentType && this.contentType.length > 0 ? `&contentType=${this.contentType}`: '')
        .concat(`&tmp=${new Date().toISOString().split('.').join('').split(':').join('')}`)
    },
    prepareCssEditor (v) {
      if (!v) return
      const config = {
        id: this.id,
        type: this.typeID,
        template: 'activity-player',
        previewURL: this.url,
      }
      return config
    },
  },
}
</script>

