<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="490"
    >
      <v-card>
        <v-card-title class="text-h5">
        </v-card-title>
        <v-card-text v-if="errorList">
          <v-row 
            v-if="errorList.length === 0"
          >
            <v-col
              cols="12"
              xs="12"
              style="text-align: center;color: #CCCCCC;"
            >
              <b>NO hay datos</b>
            </v-col>
          </v-row>
          <v-row 
            v-for="(item, index) of errorList"
            :key="index"
            style="border-bottom: 1px solid #CCCCCC;"
          >
            <v-col
              cols="12"
              xs="12"
            >
              <small style="color: #CCCCCC;">[{{item.code}}] {{convertDate(item.date)}}</small>
              <br/>
              <small><b>{{item.message}}</b></small>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            @click="onClose"
            x-small
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    errorList: {
      type: Array,
      default: null,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
  }),
  watch: {
    errorList () {
      this.dialog = this.errorList !== null ? true : false
    },
  },
  mounted () {
    this.dialog = this.errorList !== null ? true : false
  },
  methods: {
    convertDate (v) {
      if (!v) return null
      return v.replace('T', ' ').split('.').shift()
    }
  }
}
</script>
