<template>
  <v-layout wrap v-if="editedItem">
    <h2 style="margin-bottom: 20px;"><v-icon color="black" style="margin-right: 10px;">mdi-android</v-icon>{{ editedID === 'new' ? $t('common.add', locale) : $t('common.edit', locale) }} {{$t('players.player', locale).toLowerCase()}}</h2>
    <v-flex xs12 v-if="!editedItem.ID">
      <qrcode-stream v-if="isMobile" @decode="onDecode" />
      <v-text-field
        v-model="editedItem.ID"
        label="ID"
        outlined
        dense
      />
    </v-flex>
    <v-flex xs12>
      <v-text-field
        v-model="editedItem.Name"
        label="Nombre"
        outlined
        dense
      />
    </v-flex>
    <v-flex xs12>
      <v-autocomplete
        v-model="editedItem.WorkspaceID"
        :items="workspaces"
        outlined
        dense
        item-text="Name"
        item-value="ID"
        :label="`${$t('players.client', locale)} (*)`"
      />
    </v-flex>
    <!--v-flex xs12>
      <v-text-field
        v-model="editedItem.Client"
        label="Cliente"
        outlined
      />
    </v-flex-->
    <v-flex xs12>
      <v-text-field
        v-model="editedItem.Time"
        label="Time"
        outlined
        dense
        hide-details
      />
    </v-flex>
    <v-flex xs12>
      <v-checkbox
        v-model="editedItem.Active"
        label="Activo"
      />
    </v-flex>
    <v-flex xs12>
      <v-autocomplete
        v-model="editedItem.DeviceOrientation"
        :items="deviceOrientations"
        outlined
        dense
        item-text="name"
        item-value="id"
        :label="$t('players.deviceOrientation', locale)"
        hide-details
      />
    </v-flex>
    <v-flex xs12 style="padding-top:20px;">
          <v-autocomplete
            v-model="editedItem.PresentationID"
            :items="presentations"
            outlined
            dense
            item-text="Name"
            item-value="ID"
            :label="$t('players.presentation', locale)"
            hide-details
          />
    </v-flex>
    <v-flex xs12 style="padding-top:20px;">
      <v-select
        :items="APKs"
        :label="$t('players.downloadAPK', locale)"
        v-model="editedItem.DownloadAPK"
        item-text="name"
        item-value="id"
        outlined
        dense
        hide-details
      />
    </v-flex>
    <v-flex xs12>
      <action-buttons
        v-if="editedItem"
        :id="editedItem.ID"
        :onDeleteItem="handleDelete"
        :onSaveItem="handleSave"
        :onClose="onClose"
        :locale="locale"
      />
    </v-flex>
  </v-layout>
</template>
<script>
const defaultItem = {
  ID: null,
  Name: '',
  PresentationID: '140181d0-aeff-11ed-9c29-f933aac7d201',
  Time: 15,
  Active: false,

}
import { QrcodeStream } from 'vue-qrcode-reader'
import api from '@/services/api'
import apiBrightsign from '@/services/apiBrightsign'
import utils from '@/services/utils'
import ActionButtons from '@/components/ActionButtons'
export default {
  components: {
    ActionButtons, 
    QrcodeStream,
  },
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    editedID: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      default: 'es',
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    editedItem: null,
    presentations: [],
    workspaces: [],
  }),
  computed: {
    isMobile () {
      return utils.isMobile()
    },
    environment () {
      return 'players'
    },
    APKs () {
      return [
        {
          id: null,
          name: '',
        },
        { 
          id: 'https://widgets.dviewcontent.com/dapk/app-release.apk',
          name: 'Last version', 
        },
        { 
          id: 'https://widgets.dviewcontent.com/dapk/0_0_1.apk',
          name: '0.0.1', 
        },
        { 
          id: 'https://widgets.dviewcontent.com/dapk/0_0_2.apk',
          name: '0.0.2', 
        },
      ]
    },
    deviceOrientations () {
      return [
        {
          id: null,
          name: 'default'
        },
        {
          id: 'portraitDown',
          name: 'portraitDown'
        },
        {
          id: 'portraitUp',
          name: 'portraitUp'
        },
        {
          id: 'landscapeLeft',
          name: 'landscapeLeft'
        },
        {
          id: 'landscapeRight',
          name: 'landscapeRight'
        },
      ]
    },
  },
  watch: {
    'editedID' () {
      this.handleGetData()
    },
    workspaceID () {
      this.handleGetPresentations()
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetWorkspaces()
    this.handleGetPresentations()
    this.handleGetData()
  },
  methods: {
    async handleGetAPK () {
      //const url = 'v1/private/presentations/'
      const url = `v1/private/workspaces/${this.workspaceID}/presentations/`
      const aux = []
      aux.push(... await new Promise((resolve) => api.getAllWithoutLimit (this.environment, url)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          resolve([])
        })))
      this.presentations = aux
    },
    handleGetWorkspaces () {
      api.getAllWithoutLimit (this.environment, `v1/internal/users/${utils.getUser()}/workspaces`)
        .then(response => {
          this.workspaces = response
        })
    },
    async handleGetPresentations () {
      /*
      //const url = 'v1/private/presentations/'
      const url = `v1/private/workspaces/${this.workspaceID}/presentations/`
      api.getAllWithoutLimit (this.environment, url)
        .then(response => {
          response.data.unshift({ID: null, Name: ''})
          this.presentations = response.data
        })
        */
      const aux = [{ID: null, Name: ''}]
      let url = `v1/private/workspaces/${this.workspaceID}/presentations/`
      aux.push(...await new Promise((resolve) => api.getAllWithoutLimit (this.environment, url)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          resolve([])
        })))
      const network = await new Promise((resolve) => api.getItem('players', `v1/private/workspaces/`, this.workspaceID.concat('/network'))
        .then(response => {
          console.log(response)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          resolve(null)
        }))
      if (network) {
        url = `/v1/private/presentations/${network}`
        aux.push(...await new Promise((resolve) => apiBrightsign.getAll (url)
          .then(response => {
            resolve(response.map(item => {
              return {
                ID: `${network}_${item.ID}`,
                Name: `[BS] ${item.Name}`
              }
            }))
          })
          .catch(error => {
            console.log(error)
            resolve([])
          })))
      }
      
      this.presentations = aux
    },
    handleGetData () {
      if (!this.editedID) {
        this.editedItem = null
        return
      }
      if (this.editedID === 'new') this.editedItem = JSON.parse(JSON.stringify(defaultItem))
      else {
        const url = 'v1/private/android/'
        api.getItem (this.environment, url, this.editedID)
          .then(response => {
            if (response) this.editedItem = JSON.parse(JSON.stringify(response))
          })
      }
    },
    prepareData () {
      this.item = JSON.parse(JSON.stringify(this.editedItem))
    },
    onDecode (v) {
      this.item.ID = v
    },
    handleChangeZones (v) {
      this.editedItem.Zones = v
    },
    handleSave () {
      if (!this.editedItem.WorkspaceID) {
        alert('El cliente es requerido')
        return
      }

      if (!this.editedItem.ID) {
        // insert
      } else {
        //update
        const url = 'v1/private/android/'
        api.updateItem (this.environment, url, this.editedItem.ID, this.editedItem)
          .then(() => {
            this.onClose(true)
          })
      }
    },
    handleDelete () {
      const url = 'v1/private/android/'
      api.deleteItem (this.environment, url, this.editedItem.ID)
        .then(() => {
          this.onClose(true)
        })
    },
  },
}
</script>
