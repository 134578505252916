
const locale = {
  players: 'Players',
  totems: 'Totems',
  totem: 'Totem',
  player: 'Player',
  preview: 'Previsualizar',
  presentations: 'Presentaciones',
  presentation: 'Presentación',
  downloadAPK: 'Descargar version',
  template: 'Plantilla',
  deviceOrientation: 'Orientación del dispositivo',
  client: 'Cliente',
  previewVertical: 'Vista vertical en preview',
  permission: {
    advanceSearch: 'Búsqueda avanzada',
    advanceSearchInfo: '',
    android: 'Android',
    androidInfo: '',
    presentation: 'Presentación',
    presentationInfo: '',
    totem: 'Totem',
    totemInfo: '',
    brightsign: 'Brightsign',
    brightsignInfo: '',
  }
}
export default locale

