<template>
  <div>
    <v-toolbar
      elevation="0"     
    >
    <v-icon large style="margin-right:10px;">{{ icon }}</v-icon>
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
    </v-toolbar>
    <v-progress-linear indeterminate v-if="loading" />
    <v-container v-else-if="editedItem">
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-text-field
            v-bind="{ 'outlined' : true}"
            v-model="editedItem.Name"
            :label="$t('content.name', locale)"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row v-if="formFields">
        <v-col
          cols="12"
          sm="12"
          md="12"
          v-for="(item) of formFields.filter(x => !x.Value)"
          :key="item.ID"
        >
          <v-text-field
            v-if="item.Type === 'text'"
            v-bind="{ 'outlined' : true}"
            v-model="editedItem.Values[item.ID]"
            :label="item.Name"
            hide-details
          />
          <v-textarea
            v-else-if="item.Type === 'textarea'"
            v-bind="{ 'outlined' : true}"
            v-model="editedItem.Values[item.ID]"
            :label="item.Name"
            hide-details
          />
          <div
            v-else-if="item.Type === 'image'"
          >
          <small style="color: #00000080;font-size: 16px;letter-spacing: normal;">{{item.Name}}: </small>
            <image-only
              :size="{ w: item.Width ? item.Width : 100, h: item.Height ? item.Height : 100 }"
              :oldImg="editedItem.Values[item.ID]"
              @change="(v) => handleChangeImage(item, v)"
              style="margin: 0px;"
              :simpleButton="false"
            />
          </div>
          <preview-field
            :size="item.Size"
            :value="editedItem.Values[item.ID]"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <custom-label
            :label="$t('content.time', locale).concat(':')"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <interval-time
             v-model="editedItem.Time"
             style="width: 100px"
          />
        </v-col>
      </v-row>
      <v-row>
        <availability
          v-model="editedItem.Availability"
          :locale="locale"
          @change="handleChangeAvailability"
        />
      </v-row>
    </v-container>
    <action-buttons
      v-if="editedItem"
      :id="editedItem.ID"
      :onDeleteItem="handleDelete"
      :onSaveItem="handleSave"
      :onClose="handleClose"
      :locale="locale"
    />
    <a style="color:white" :href="`https://widgets-external.dtouchwebapp.com/meetingRoomPage/index.html?contentTypeID=${contentTypeData.ID}&contentID=${editedID}`">.</a>
  </div>
</template>
<script>
const defaultItem = {
  Name: '',
  Values: {},
  Images: {},
  IsTemp: false,
  Time: 10,
  Availability: null,
}

import { mapState } from 'vuex'
import api from '@/services/api'
import ActionButtons from '@/components/ActionButtons'
import Availability from '@/components/Availability'
import CustomLabel from '@/components/CustomLabel'
import IntervalTime from '@/components/intervalTimes/IntervalTime'
import ImageOnly from '../mediaForm/ImageOnly'
import PreviewField from './PreviewField'

export default{
  name: 'meetingRoomForm',
  components: {
    ActionButtons,
    Availability,
    CustomLabel,
    IntervalTime,
    ImageOnly,
    PreviewField,
  },
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    contentTypeData: {
      type: Object,
      required: true
    },
    editedID: {
      type: String,
      default: null
    },
    onClose: {
      type: Function,
      required: true
    },
    onDelete: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    loading: false,
    editedItem: null,
    formFields: null,
    width: null,
    height: null,
  }),
  computed: {
    ...mapState('app',['locale']),
  },
  watch: {
    editedID () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetDataForm()
    this.handleGetData()
  },
  methods: {
    handleGetDataForm () {
      api.getItem ('dviewcontent', 'v1/public/content-types/', `${this.contentTypeData.ID}/meeting-room`)
        .then(response => {   
          if (!response) return
          this.width = response.Width
          this.height = response.Height
          this.formFields = response.formFields
        })
    },
    handleGetData () {
      this.loading = true
      if (!this.editedID) {
        this.editedItem = JSON.parse(JSON.stringify(defaultItem))
        // req Iván
        this.editedItem.Availability = {
          Per: 1,
          Rec: 1,
          IsAllDays: true,
          IsAllTimes: false,
          DayOfMonth: [],
          DayOfWeek: [],
          Alt: 1,
          TimeStart: 480,
          TimeEnd: 540,
          DateStart: new Date(),
          DateEnd: new Date(),
        }

        this.editedItem.ContentTypeDataID = this.contentTypeDataID
        this.loading = false
      } else {
        api.getItem ('dviewcontent', `v1/private/meeting-room/${this.contentTypeData.ID}/`, this.editedID)
        .then(response => {   
          response.Values = response.FormValues ? JSON.parse(response.FormValues) : {}
          response.Images = {}
          this.editedItem = response
          this.loading = false
        })
      }
    },
    handleSave () {
      console.log('ff', this.formFields.filter(x => x.Value))
      for (const item of this.formFields.filter(x => x.Value)) {
        this.editedItem.Values[item.ID] = item.Value
      }
      console.log('ei', this.editedItem)
      this.editedItem.Width = this.width
      this.editedItem.Height = this.height
      if (this.editedItem.ID)
        api.updateItem ('dviewcontent', `v1/private/meeting-room/${this.contentTypeData.ID}/`, this.editedItem.ID, this.editedItem)
          .then(() => {   
            this.onClose()
          })
      else 
        api.addItem ('dviewcontent', `v1/private/meeting-room/${this.contentTypeData.ID}`, this.editedItem)
          .then(() => {   
            this.onClose()
          })
    },
    handleDelete () {
      this.onDelete(this.editedID)
    },
    handleClose () {
      this.onClose()
    },
    handleChangeAvailability (v) {
      this.editedItem.Availability = v
    },
    handleChangeImage (item, v) {
      this.editedItem.Images[item.ID] = v
    },
  },
}
</script>

