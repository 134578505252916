<template>
  <div
    style="position:absolute;"
    :style="{
      backgroundColor: backgroundColor,
      color: color,
      fontSize: fontSize,
      top: top,
      right: right,
      width: width,
      height: height
    }"
  >
    <div v-if="showInfo" style="position:absolute; color: white;background-color:#CCCCCC80;padding: 5px 10px;">{{zone.Name}}</div>
    <custom-carousel 
      v-if="contents"
      :contents="contents"
    />
  </div>
</template>
<script>
import { parseString } from 'xml2js'
import CustomCarousel from './CustomCarousel'
export default {
  components: {
    CustomCarousel,
  },
  props: {
    showInfo: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    fontSize: {
      type: String,
      required: true,
    },
    top: {
      type: String,
      required: true,
    },
    right: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: true,
    },
    originalWidth: {
      type: String,
      required: true,
    },
    originalHeight: {
      type: String,
      required: true,
    },
    xmls: {
      type: Array,
      required: true,
    },
    zone: {
      type: Object,
      requried: true,
    },
    scaleFactor: {
      type: Number,
      required: true,
    },
    speedFactor: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    contents: [],
  }),
  computed: {
    forceScale () {
      return 0.42
    },
  },
  watch: {
    'item.XML' () {
      this.handleGetItems()
    },
    xml () {
      this.handleGetItems()
    },
    speedFactor () {
      this.handleGetItems()
    },
  },
  mounted () {
    this.handleGetItems()
  },
  methods: {
    prepareClock () {
      const event = new Date()
      const options = this.zone.TypeClock === 'date' ? {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      } : {
        hour: 'numeric', hour12: false, minute: 'numeric' 
      }

      const text = this.zone.TypeClock === 'date' ? event.toLocaleDateString('es-ES', options) : event.toLocaleDateString('es-ES', options).split(',').pop().split(':').map(item => `0${item.replace(' ', '')}`.slice(-2)).join(':')
      return {
        text,
        w: this.width.replace('px', '') | 0,
        h: this.height.replace('px', '') | 0,
      }
      /*
      //const sw = text.length * 5
      const sw = (this.width.replace('px', '') | 0) / text.length
      const sh = (this.height.replace('px', '') | 0) * 7
      console.log(sw, sh)
      return `<div class="module" style="line-height:1;font-size: ${sw}px;height:${this.height} !important;background-color: ${this.backgroundColor};color:${this.color ? this.color : 'red'};"><h2>${text}</h2></div>`
*/
/*
        return `<custom-text :value="
${
event.toLocaleDateString('es-ES', options)}" />`
        return `<div class="module" style="line-height:1;font-size: calc((75vw - 4.5rem) / 7);height:${this.height} !important;background-color: ${this.backgroundColor};font-size:14px;color:${this.color ? this.color : 'red'};">


<svg viewBox="0 0 56 ${this.height.replace('px','')}">
  <text x="0" y="0">
${event.toLocaleDateString('es-ES', options)}
</text>
</svg>
</div>`
        return `<div style="padding-top:5px;height:${this.height} !important;background-color: ${this.backgroundColor};font-size:10px;color:${this.color ? this.color : 'red'};">
                <b>${event.toLocaleDateString('es-ES', options)}</b>
              </div>`
      } else {
        const event = new Date()
        const options = {
          hour: 'numeric', hour12: false, minute: 'numeric' 
        }
        let time = event.toLocaleDateString('es-ES', options).split(',').pop()
        time = time ? time.split(':').map(item => `0${item.replace(' ', '')}`.slice(-2)).join(':') : null
        
        return `<div class="module" style="line-height:1;font-size: calc((75vw - 4.5rem) / 7);height:${this.height} !important;background-color: ${this.backgroundColor};font-size:14px;color:${this.color ? this.color : 'red'};"><h2>${time}</h2></div>`
        //return `<div style="line-height:1;font-size: calc((75vw - 4.5rem) / 7);height:${this.height} !important;background-color: ${this.backgroundColor};font-size:14px;color:${this.color ? this.color : 'red'};">${time}</div>`

        /*return `<div style="line-height:1;font-size:${this.height} !important;height:${this.height} !important;background-color: ${this.backgroundColor};font-size:14px;color:${this.color ? this.color : 'red'};">
              ${time}
              </div>`*/
        
          /*
        return `<div style="padding-top:6px;height:${this.height} !important;background-color: ${this.backgroundColor};font-size:14px;color:${this.color ? this.color : 'red'};">
                <b>${time}</b>
              </div>`
      }
              */
    },
    prepareMessage (v, duration) {
      return `<div class="message-preview" style="height:${this.height} !important;background-color: ${this.backgroundColor};">
              <span class="p-preview" style="animation-duration: ${duration}s;font-size:${this.fontSize}px;color:${this.color};">${v}</span>
              </div>`
    },
    prepareDurationWidhtSpeedFactor (v) {
      const aux = v / this.speedFactor
      return aux < 1 ? 1 : aux | 0
    },
    prepareMediaContent (type, url, description, duration) {
      if (!duration || duration.length === 0) duration = 1
      const content = type === 'message'? this.prepareMessage(description, duration) : type === 'html' ? `<iframe class="frame" scrolling="no" src="${url}" style="z-index:100;border:0;width:${this.originalWidth};height:${this.originalHeight};-ms-zoom: ${this.forceScale || this.scaleFactor};-moz-transform: scale(${this.forceScale || this.scaleFactor});-moz-transform-origin: 0 0;-o-transform: scale(${this.forceScale || this.scaleFactor});-o-transform-origin: 0 0;-webkit-transform: scale(${this.forceScale || this.scaleFactor});-webkit-transform-origin: 0 0;" />`
        : type === 'mp4' ? `<video src="${url}" width="100%" height="100%" loop
            autoplay="autoplay" muted defaultmuted playsinline />`
        : `<img src="${url}" width="100%" height="100%">`
      return {
        content,
        duration: this.prepareDurationWidhtSpeedFactor(duration),
        type
      }
    },
    async handleGetItems () {
      try {
        const contents = []
        if (this.zone.Type === 'Clock') {
          contents.push({ content: this.prepareClock(), duration: 10000, type: 'Clock' })
        } else {
          if (!this.xmls || this.xmls.length === 0) return
          for (const xml of this.xmls) {
            if (xml.indexOf('.xml') < 0) {
              const params = xml.split('https')
              if (params.length === 2) {
                contents.push(this.prepareMediaContent ('html', `https${params[1]}`, null, params[0]))
              } else {
                contents.push(this.prepareMediaContent ('html', `https${params[0]}`, null, 10))
              }
              continue
            }
            /*
            //const data = await new Promise((resolve, reject) => axios.get(xml, { headers: {"Authorization" : `Bearer ${tokenStr}`} })
            const data = await new Promise((resolve, reject) => axios.get(xml, { headers: {} })
              .then (response => {
                resolve(response.data)
              })
              .catch (error => {
               reject(error)
              }))*/
            const data = await new Promise((resolve) => fetch(xml)
              .then(response => response.text())
              .then(data => resolve(data))
              .catch(() => resolve(null))
            )
            if (data) {
              const results = await new Promise((resolve, reject) => parseString(data, (err, results) => {
                if (err) reject(err)
                else resolve(results)
              }))

              if (!results || !results.rss || !results.rss.channel) continue
              const channel = results.rss.channel.shift()
              if (!channel || !channel.item) continue
              contents.push(...channel.item.map(item => {
                if (item['media:content']) {
                  const { url, type, description, duration } = item['media:content'].shift()['$']
                  return this.prepareMediaContent(type, url, description, duration)
                } else if (item.description) {
                  let time = ((item.description.length /200) | 0)
                  time = (time ? time : 1) * 25
                  return this.prepareMediaContent('message', null, item.description, time)
                }
              }))
            }
          }
        } // else isClock
        this.contents = contents
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
<style>
.module h2 {
  font-size: 1em;
  container-name: sidebar
}

@container sidebar (min-width: 700px) {
  .module h2 {
    font-size: 2em;
  }
}
</style>

