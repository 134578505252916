<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Ocultar/mostrar evento
        </v-card-title>

        <v-card-text v-if="hideEvent" style="padding: 20px;">
          <div style="margin: 0 0 20px 0;line-height:1.4;font-style: italic;">
            Se ocultará el evento del día/hora seleccionado.
          </div>
          <v-btn
            @click="handleHide"
            :color="hideEvent.HideEvent ? 'green' : 'red'"
            dark
            block
          >
            {{ hideEvent.HideEvent ? 'Mostrar evento concreto' : 'Ocultar evento concreto' }}
          </v-btn>
        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            text
            @click="onClose(false)"
          >
            {{ $t('common.close', locale)}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import api from '@/services/api'
export default {
  props: {
    contentTypeID: {
      type: String,
      requried: true,
    },
    hideEvent: {
      type: Object,
      default: null,
    },
    onClose: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      requried: true,
    },
  },
  data: () => ({
    dialog: false,
  }),
  watch: {
    hideEvent (v) {
      this.dialog = v !== null ? true : false 
    },
  },
  methods: {
    handleHide () {
      const body = {
        AvailabilityID: this.hideEvent.AvailabilityID,
        Date: this.hideEvent.date
      }
      const url = `v1/private/events-manager/${this.contentTypeID}/hide-recurrent`
      api.put ('dviewcontent', url, body)
        .then(() => {
          this.onClose(true)
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

