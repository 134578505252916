var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.templates)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticStyle:{"padding":"20px"},attrs:{"xs8":""}},[_c('div',{staticStyle:{"padding":"10px","background-color":"white"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers.filter(function (x) { return x.value !== 'WorkspaceName' || _vm.items.filter(function (x) { return !x.WorkspaceID; }).length > 0; }),"items":_vm.items,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs":"6"}},[_c('h1',[_c('v-icon',{attrs:{"large":"","color":"black"}},[_vm._v("mdi-presentation-play")]),_vm._v(" "+_vm._s(_vm.$t('players.presentations', _vm.locale)))],1)]),_c('v-flex',{attrs:{"xs":"6"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticStyle:{"padding-left":"10px"},attrs:{"xs6":""}},[_c('search',{attrs:{"onSearch":_vm.handleSearch,"showClear":_vm.searchValue && _vm.searchValue.length > 0 ? true : false,"maxWidth":400}})],1),_c('v-flex',{staticStyle:{"text-align":"right"},attrs:{"xs6":""}},[_c('v-btn',{attrs:{"block":""},on:{"click":function($event){return _vm.handleGetData(null)}}},[_vm._v(" "+_vm._s(_vm.$t('common.refresh', _vm.locale))+" ")])],1)],1)],1)],1)]},proxy:true},{key:"item.ident",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){_vm.editedID = item.ID}}},[_vm._v(_vm._s(item.Name ? item.Name : item.ID))])]}},{key:"item.template",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.templates.filter(function (x) { return x.ID===item.TemplateID; }).map(function (item) { return item.Name; }).shift())+" ")]}},{key:"item.externalPreview",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.getPreviewURL(item)}}},[_c('v-icon',[_vm._v("mdi-file-find")])],1)]}},{key:"item.Players",fn:function(ref){
var item = ref.item;
return [_c('template-players',{attrs:{"presentationID":item.ID,"workspaceID":_vm.workspaceID,"onClick":_vm.handleEditPlayer}})]}}],null,false,344781665)}),(_vm.count != null)?_c('pagination',{attrs:{"startIndex":_vm.startIndex,"count":_vm.count,"itemsPerPage":_vm.itemsPerPage,"onGetData":_vm.handleGetData,"locale":_vm.locale,"onChangeItemsPerPage":_vm.handleChangeItemsPerPage,"arrayPages":[5, 10, 15, 20, 25, 30, 50]}}):_vm._e()],1)]),(!_vm.editedPlayerID)?_c('v-flex',{staticStyle:{"padding":"20px 20px 0 0"},attrs:{"xs4":""}},[_c('div',{staticStyle:{"padding":"10px","background-color":"white","min-height":"calc(100vh)"}},[(!_vm.editedID)?_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":_vm.handleAddItem}},[_vm._v(" "+_vm._s(_vm.$t('common.add', _vm.locale))+" ")]):_vm._e(),(_vm.editedID)?_c('presentation-form',{attrs:{"workspaceID":_vm.workspaceID,"editedID":_vm.editedID,"onClose":_vm.handleClose,"locale":_vm.locale,"templates":_vm.templates}}):_vm._e()],1)]):_c('v-flex',{staticStyle:{"padding":"20px 20px 0 0"},attrs:{"xs4":""}},[_c('div',{staticStyle:{"padding":"10px","background-color":"white","min-height":"calc(100vh)"}},[_c('android-form',{attrs:{"workspaceID":_vm.workspaceID,"editedID":_vm.editedPlayerID,"onClose":_vm.handleClose,"locale":_vm.locale}})],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }