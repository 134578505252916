var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"absolute"},style:({
    backgroundColor: _vm.backgroundColor,
    color: _vm.color,
    fontSize: _vm.fontSize,
    top: _vm.top,
    right: _vm.right,
    width: _vm.width,
    height: _vm.height
  })},[(_vm.showInfo)?_c('div',{staticStyle:{"position":"absolute","color":"white","background-color":"#CCCCCC80","padding":"5px 10px"}},[_vm._v(_vm._s(_vm.zone.Name))]):_vm._e(),(_vm.contents)?_c('custom-carousel',{attrs:{"contents":_vm.contents}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }