<template>
  <v-btn
    v-if="!dialog"
    color="orange"
    dark
    @click="dialog = true"
  >
    css
  </v-btn>
  <v-row justify="center" v-else>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <!--template v-slot:activator="{ on, attrs }">
        <v-btn
          color="orange"
          dark
          v-bind="attrs"
          v-on="on"
        >
          css
        </v-btn>
      </template-->
      <v-card style="background-color: #CCCCCC;">
        <v-toolbar
          dark
          color="primary"
          elevation="0"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            CSS
            <small style="color: red;font-style: italic;"> . [ <b>CUIDADO</b>: esto afecta a TODAS las visualizaciones que compartan ID ] .</small>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              dark
              color="orange"
              small
              text
              @click="handleSave(true)"
            >
              {{ $t('common.preview', locale)}}
            </v-btn>
            <v-btn
              dark
              text
              @click="handleSave(false)"
            >
              {{ $t('common.save', locale)}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-layout>
          <v-flex 
            v-if="config.previewURL" 
            xs6
            class="previewCSS"
            style="padding: 10px 5px 10px 10px;text-align:center;"
          >
            <preview 
              v-if="parentWidth && config.previewURL"
              :url="preparePreviewCSS(forcePreview)"
              locale="es"
              :size="{
                w: 1920,
                h: 1080
              }"
              :parentWidth="parentWidth"
            />
          </v-flex>
          <v-flex :xs6="config.previewURL" :xs12="!config.previewURL" style="text-align:center;">
            <div style="margin: 10px;background-color: white;">
              <textarea-tab v-model="value" />
            </div>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import apiService from '@/services/api'
import TextareaTab from './TextareaTab'
import Preview from '../Preview'
export default {
  components: {
    TextareaTab,
    Preview,
  },
  props: {
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: 'es',
    },
    onSave: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    value: '',
    parentWidth: null,
    sectionStart: 0,
    sectionEnd: 0,
    forcePreview: null,
  }),
  computed: {
    id () {
      return this.config && this.config.id ? this.config.id : null
    },
  },
  watch: {
    'config.id' () {
      this.handleGetData()
    },
    dialog (v) {
      if (v) {
        this.handleGetData()
        setTimeout(() => {
          this.parentWidth = document.getElementsByClassName('previewCSS')[0].offsetWidth - 10
        }, 1000)
      } else {
        this.forcePreview = null
      }
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    preparePreviewCSS (v) {
      return v ? `${this.config.previewURL}&previewCSS=${v}` : `${this.config.previewURL}`
    },
    handleGetData () {
      if (!this.id) return
      apiService.getItem('players', 'v1/system/css/', `${this.id}/${this.config.template}`)
        .then(response => {
          this.value = response ? JSON.parse(response) : ''
        })
    },
    async handleSave (preview) {
      const body = {
        css: preview ? null : JSON.stringify(this.value),
        previewCSS: preview ? JSON.stringify(this.value) : null,
      }
      await new Promise((resolve) => apiService.updateItem('players', 'v1/system/css/', `${this.id}/${this.config.template}`, body)
        .then(() => {
          resolve()
        }))

      if (preview) {
        this.forcePreview = new Date().toISOString().split('.').shift()
      } else {
        this.onSave()
        this.dialog = false
      }
    },
  },
}
</script>
