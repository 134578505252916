<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          block
        >
          Administrar puntos
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
          elevation="0"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Administrar puntos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="handleSavePoints"
            >
              {{ $t('common.save', locale) }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-layout wrap>
          <v-flex xs8 style="padding-top: 10px;">
            <custom-canvas 
              v-if="width && height && scale"
              :key="lmu"
              :locale="locale"
              :seatMapBackground="value.Image"
              :onChange="handleChange"
              :width="width"
              :height="height"
              :scale="scale"
              :lmu="lmu"
              :editedItem="points" 
            />
          </v-flex>
          <v-flex xs4 style="padding: 10px;">
            <v-btn
              v-if="!editedItem"
              @click="handleAddPoint"
              color="primary"
              block
            >
              {{$t('common.add', locale)}}
            </v-btn>
            <v-layout wrap v-if="!editedItem">
              <v-flex xs12 style="padding-top: 20px;">
                <draggable
                  v-model="points"
                  :draggable="'.itemDraggable'"
                  style="width:100%;"
                  v-bind="dragOptions"
                  @start="handleSortStart"
                  @end="handleSortItems(points)"
                >
                <div style="cursor: pointer;" 
                  v-for="item in points" 
                  :key="item.ID" 
                  v-bind:class="'itemDraggable'"
                >
                    <v-toolbar
                      elevation="1"
                      :style="{ 'padding-left' : `${item.Level * 20}px`}"
                    >
                      <!-- <v-app-bar-nav-icon>mdi-lead-pencil</v-app-bar-nav-icon> -->
                      <v-icon small style="margin-right: 10px;">mdi-cursor-move</v-icon>
                      <a @click="editedItem = item" style="cursor: pointer;">
                        <v-icon small style="margin-right: 10px;" v-if="item.Type" color="primary">{{ getTypeIcon(item.Type) }}</v-icon>
                      </a>
                      <v-toolbar-title>
                        <a @click="editedItem = item" style="cursor: pointer;">
                                      {{item.Pos}} - {{ getTranslation(item.Title, locale) }}
                        </a>
                      </v-toolbar-title>
                      <!--v-spacer />
                      <v-btn
                        fab
                        x-small
                        elevation="0"
                        @click.stop.prevent="onHide(item)"
                        :color="item.Hide ? 'red' : 'green'"
                        dark
                      >
                        <v-icon x-small>mdi-eye</v-icon>
                      </v-btn-->
                    </v-toolbar>
                  </div>
                </draggable>
              </v-flex>
            </v-layout>
            <point-form
              :categories="categories"
              :value="editedItem"
              :locale="locale"
              :onClose="handleClosePointForm"
              :onDelete="handleDeletePointForm"
              :onSave="handleSavePointForm"
              :languages="languages"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import draggable from 'vuedraggable'
import apiDtouch from '@/services/apiDtouch'
import utilsService from '@/services/utils'
import CustomCanvas from './CustomCanvas'
import PointForm from './PointForm'
export default {
  components: {
    CustomCanvas,
    PointForm,
    draggable,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    width: null,
    height: null,
    scale: null,
    lmu: 123,
    editedItem: null,
    points: [],
    lastPos: 1
  }),
  computed: {        
    dragOptionsDisabled() {
      return {
        animation: 200,
        group: "description",
        disabled: true,
        ghostClass: "ghost"
      };
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  mounted () {
    window.addEventListener('resize', this.prepareScale)
    this.handleGetPoints()
    this.prepareScale()
  },
  unmounted() {
    window.removeEventListener('resize', this.prepareScale)
  },
  methods: {
    prepareScale () {
      const w = (document.getElementsByTagName('body')[0].clientWidth -50) / 12 * 8
      this.scale = w > this.value.Width ? 1 : w / this.value.Width
      this.width = this.value.Width * this.scale | 0
      this.height = this.value.Height * this.scale | 0
      this.lmu = utilsService.getNewID()
    },
    getTranslation (v, locale) {
      return utilsService.getTranslation(v, locale)
    },
    handleGetPoints () {
      apiDtouch.getAll(`v2/private/interactive-maps/${this.value.ID}/points`)
        .then(response => {
          this.points = response.map(item => {
            item.Title = JSON.parse(item.Title)
            item.Description = JSON.parse(item.Description)
            item.Info = item.Info ? JSON.parse(item.Info) : { es: '' }
            return item
          })
        })
    },
    handleAddPoint () {
      this.editedItem = {
        ID: utilsService.getNewID(),
        InteractiveMapsID: this.value.ID,
        Pos: this.points.length + 1,
        Title: {es: `Point ${this.points.length + 1}` },
        Description: {es: '' },
        Info: {es: '' },
        Width: 30,
        Height: 30,
        X: 0,
        Y: 0,
        Scale: null,
        Image: null,
        BGColor: '#00FFFF',
        Color: '#FFFFFF',
        new: true,
        ORD: this.points.length + 1
      }
    },
    handleChange (v) {
      this.points = v
    },
    handleDeletePointForm (v) {
      this.points = this.points.filter(x => x.ID !== v)
      this.handleClosePointForm()
    },
    handleClosePointForm () {
      this.editedItem = null
    },
    handleSavePointForm (v) {
      if (v) {
        this.points = this.points.filter(x => x.ID !== v.ID)
        this.points.push(v)
        this.points = this.points.sort((a,b) => {
          return a.ORD - b.ORD
        })
      }
      this.handleClosePointForm()
    },
    handleSavePoints () {
      apiDtouch.put(`v2/private/interactive-maps/${this.value.ID}/points`, this.points)
        .then(() => {
          this.dialog = false
        })
    },
    handleSortItems (v) {
      this.points = v
      if (this.points && this.points.length)
        for (let i = 0; i < this.points.length; i++) {
          this.points[i].ORD = i
        }
    },
    handleSortStart () {
    },
    activeDragable () {
    },
  },
}
</script>
