<template>
  <v-layout wrap>
    <v-flex xs12 class="title">
      <h2 :style="{ color: cronData.length > 1 ? 'red' : 'black' }">
        Cajasiete STATUS 
      </h2>
    </v-flex>
    <template v-for="(item, index) of cronData">
      <v-flex xs1 :key="`lmd${index}`" class="item">
         ⏱ {{ item.date }}
      </v-flex>
      <v-flex xs7 :key="`total${index}`" class="item">
        {{ item.name }}
      </v-flex>
      <v-flex xs4 :key="`duration${index}`"  class="item">
        {{ item.url }}
      </v-flex>
    </template>
    <v-flex xs12 class="action">
      <a @click="handleGetCronResult">refresh</a>
    </v-flex>
  </v-layout>
</template>
<script>
import axios from 'axios'
export default {
  data: () => ({
    date: null,
    cronData: []
  }),
  mounted () {
    this.handleGetCronResult()
  },
  methods: {
    prepareDate (v) {
      return v.map(item => item.date).shift()
    },
    handleGetCronResult () {
      axios.get(`https://rss-dviewcontent-api.dviewcontent.com/cron/cajasiete-board`)
        .then(response => {
          if (!response) return 
          this.cronData = response.data
        })
    },
  },
}
</script>
<style scoped>
.title {
  background-color: #ccc;
  text-align: center;
  padding: 20px 10px;
}
.item {
  text-align: center;
  padding: 20px 10px;
  border-bottom: 1px solid #CCCCCC80;
}
.action {
  padding: 10px;
  text-align: right;
}
</style>

