<template>
  <draggable
    :list="items"
    tag="tbody"
    :draggable="'.itemDraggable'"
    @end="onSort(items)"
  >

    <tr
      v-for="(item, index) in items"
      :key="index"
      class="itemDraggable"
    >
      <td width="30px">
        <v-icon
          small
          class="page__grab-icon"
        >
          mdi-cursor-move
        </v-icon>
      </td>
      <td width="100px" style="padding:0px;">
        <v-img
          v-if="item.Screenshot"
          :src="item.Screenshot"
          style="width: 80px;height: auto;"
        />
        <a
          v-else
          :href="item.MediaURL"
          target="_blank"
        >
        <v-img
          :src="item.MediaURL"
          style="width: 80px;height:auto;"
        />
          </a>
      </td>
      <td>
        <a @click="onEditItem(item.ID)" style="cursor: pointer;" color="primary"><b>{{ item.Name && item.Name.length > 0 ? item.Name : '...' }}</b></a><br />
        <span>{{ item.Type }}</span>
        <span v-html="prepareURL(item.URL)"></span>
      </td>
      <td width="100px" style="text-align: center;">
        {{ handleConvertTime(item.Time) }}
      </td>
      <td style="text-align:left;">
        <availability-info :item="item" :locale="locale" />
      </td>
      <td style="text-align:left;">
        <availability-icon :item="item.Status" />
      </td>
      <!--td style="text-align:right;">
        <v-icon
          v-if="onEditItem"
          small
          class="mr-2"
          @click="onEditItem(item.ID)"
        >
          mdi-pencil
        </v-icon>
        <delete-item
          v-if="onDeleteItem"
          :id="item.ID"
          :onDeleteItem="onDeleteItem"
          icon="mdi-delete"
          :locale="locale"
        />
      </td-->
    </tr>
  </draggable>
</template>
<script>
  import draggable from 'vuedraggable'
  import utils from '@/services/utils'
  import AvailabilityInfo from './AvailabilityInfo'
  import AvailabilityIcon from './AvailabilityIcon'
  export default{
    components: {
      draggable,
      AvailabilityInfo,
      AvailabilityIcon,
    },
    props: {
      items: {
        type: Array,
        required: true,
      },
      onDeleteItem: {
        type: Function,
        default: null,
      },
      onSort: {
        type: Function,
        default: null,
      },
      onEditItem: {
        type: Function,
        default: null,
      },
      locale: {
        type: String,
        required: true,
      }
    },
    methods: {
      prepareURL (v) {
        if (!v) return
        else {
          const limit = 50
          if (v.length > limit) return `<br />${v.substring(0, limit)}...`
          else return `<br />${v}`
        }
      },
      handleConvertTime (v) {
        return utils.convertTime(v)
      }
    }
  }
</script>
<style scoped>
.itemDraggable td{
  border-bottom: 1px solid #fcf9f9 !important;
}
.itemDraggable:nth-child(odd){
  background: #fcf9f9;
}
.itemDraggable:nth-child(even){
  background: white;
}
</style>

