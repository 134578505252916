<template>
  <div style="min-height: calc(100vh);"
    :style="{ width: parentWidth ? `${parentWidth}px`: null }"
  >
    <iframe 
      id="previewDtouch"
      :src="url"
      style="border-radius: 5px;"
      :style="{
          width: `${size.w}px`,
          height: `${size.h}px`,
          'background-color': 'transparent',
          '-ms-zoom': scale,
          '-moz-transform': `scale(${scale})`,
          '-moz-transform-origin': '0 0',
          '-o-transform': `scale(${scale})`,
          '-o-transform-origin': '0 0',
          '-webkit-transform': `scale(${scale})`,
          '-webkit-transform-origin': '0 0',
      }"
    />
    <small style="opacity: 0;">{{parseInt(parentWidth)}} x {{parseInt(height)}}</small>
  </div>
</template>
<script>
export default {
  props: {
    url: {
      type: String,
      required: true
    },
    locale: {
      type: String,
      required: true,
    },
    size: {
      type: Object,
      required: true,
    },
    className: {
      type: String,
      defautl: 'preciew',
    },
    parentWidth: {
      type: [String, Number],
      default: null,
    },
  },
  data: () => ({
    height: 0,
    scale: 0,
  }),
  watch: {
    parentWidth () {
      this.prepareSize()
    },
  },
  mounted () {
    this.prepareSize()
  },
  methods: {
    prepareSize () {
      if (this.parentWidth) {
        let a = this.parentWidth
        this.height = this.size.h * a / this.size.w
        this.scale = (a * 100 / this.size.w) / 100
      } else {
        setTimeout(() => {
          let a = this.parentWidth ? this.parentWidth : document.getElementsByClassName(this.className)[0].offsetWidth - 10
          this.height = this.size.h * a / this.size.w
          this.scale = (a * 100 / this.size.w) / 100
        }, 1000)
      }
    },
    setUserAgent(window, userAgent) {
      if (window.navigator.userAgent != userAgent) {
        var userAgentProp = {
          get: function() {
            return userAgent;
          }
        };
        try {
          Object.defineProperty(window.navigator, 'userAgent', userAgentProp);
        } catch (e) {
          window.navigator = Object.create(navigator, {
            userAgent: userAgentProp
          });
        }
      }
    },
  },
}
</script>




