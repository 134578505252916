<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
  
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="selected"
            :items="groups"
            outlined
            dense
            label="Grupo"
            itemText="Name"
            itemValue="ID"
            hideDetails
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="onClose"
          >
            cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click="handleSave"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import apiBrightsign from '@/services/apiBrightsign'
export default {
  props: {
    device: {
      type: Object,
      default: null,
    },
    network: {
      type: String,
      default: null,
    },
    onClose: {
      type: Function,
      required: true,
    },
    onSync: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    groups: [],
    selected: null,
  }),
  watch: {
    device (v) {
      this.dialog = v ? true : false
      this.handleGetData()
    },
    'device.deviceID' () {
      this.handleGetData()
    },
  },
  methods: {
    handleGetData () {
      this.groups = []
      if (!this.device) return
      this.newGroupID = this.device.groupID
      apiBrightsign.getAll(`/v1/private/groups-presentations/${this.network}`)
        .then(response => {
          this.groups = response.map(item => {
            return {
              ID: {
                network: this.network,
                oldGroupID: this.device.groupID,
                oldPresentationID: this.device.presentationID,
                newGroupID: item.ID,
                newPresentationID: item.PresentationID,
              },
              Name: `${item.Name}- ${item.PresentationName}`
            }
          })
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    },
    handleSave () {
      if(!this.selected ||!this.device.deviceID)
        return
      apiBrightsign.put(`/v1/private/devices/${this.device.deviceID}/move-group`, this.selected)
        .then(() => {
          this.onSync(this.device.deviceID)
          this.onClose()
        })
        .catch(error => {
          console.log(error.response.status)
          console.log(error)
        })
    },
  },
}
</script>

