<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
          elevation="0"
        >
          <v-btn
            icon
            dark
            @click="onClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="handleSave"
            >
              {{ $t('common.save', locale)}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <!--v-list
          three-line
          subheader
        >
          <v-subheader>User Controls</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Content filtering</v-list-item-title>
              <v-list-item-subtitle>Set the content filtering level to restrict apps that can be downloaded</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Password</v-list-item-title>
              <v-list-item-subtitle>Require password for purchase or use password to restrict purchase</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-->
        <v-divider/>
        <div style="padding: 10px 20px;background-color: #CCCCCC80;">
        </div>
        <v-layout wrap 
          v-for="(item, index) of urls"
          :key="index"
          :style="{ backgroundColor: item.error ? 'red' : '#CCCCCC80' }"
        >
          <v-flex xs2 style="padding: 10px">
            <v-text-field
              v-model="item.time"
              label="Time (seg)"
              hide-details
              outlined
              dense
            />
          </v-flex>
          <v-flex xs9 style="padding: 10px">
            <v-text-field
              prepend-inner-icon="mdi-information-variant-circle"
              v-model="item.url"
              label="XML / URL"
              hide-details
              outlined
              dense
              @click:prepend-inner="handleShowURLInfo(item.url)"
            />
          </v-flex>
          <v-flex xs1 style="padding: 10px">
            <v-btn
              @click="() => { urls.splice(index, 1)}"
              color="red"
              dark
              block
            >
              {{ $t('common.delete', locale)}}
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout wrap style="background-color:#CCCCCC80;">
          <v-flex xs12 style="padding: 10px">
            <v-btn
              @click="() => { urls.push({time: '', url: '' })}"
              color="primary"
              block
            >
              {{ $t('common.add', locale)}}
            </v-btn>
          </v-flex>
          <v-flex xs12 style="padding: 10px;text-align: center;font-style: italic;">
            <small>NOTA: Todo lo que no sea XML requiere informar el <b>TIEMPO</b> en segundos</small>
            <url-info 
              :url="selectedURLInfo"
              :onClose="() => { selectedURLInfo = null }"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import UrlInfo from './UrlInfo'
export default {
  components: {
    UrlInfo,
  },
  props: {
    editedItem: {
      type: Object,
      default: null,
    },
    onClose: {
      type: Function,
      required: true,
    },
    onSave: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      requried: true,
    },
  },
  data: () => ({
    dialog: false,
    urls: [],
    result: '',
    selectedURLInfo: null,
  }),
  watch: {
    editedItem () {
      this.prepareDialog()
    },
  },
  methods: {
    convertURL (v) {
      if (!v || v.length === 0) return []
      let aux = v.split(';')
      aux = aux.map(item => {
        const i = item.split('http')
        return {
          url: `http${i.pop()}`,
          time: i.shift(),
        }
      })
      return aux
    },
    prepareDialog () {
      this.result = ''
      this.urls = []
      
      if (!this.editedItem) {
        this.dialog = false
        return
      } 

      if (this.editedItem.item && this.editedItem.item.XML) {
        this.urls = this.convertURL(this.editedItem.item.XML)
      }

      this.dialog = true
    },
    handleSave () {
      let isValid = true
      this.urls = this.urls.map(item => {
        item.error = false
        if (item.url.toLowerCase().indexOf('.xml') === -1) {
          if (!item.time || item.time.length === 0) {
            item.error = true
            isValid = false
          }
        } else {
          item.time = ''
        }
        return item
      })
      if (!isValid) {
        alert('error')
        return
      }
      this.result = this.urls.map(item => `${item.time}${item.url}`).join(';')
      this.onSave(this.editedItem.index, this.result)
    },
    handleShowURLInfo (v) {
      this.selectedURLInfo = v
    },
  },
}
</script>

