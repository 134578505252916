<template>
  <v-card
    class="mx-auto"
  >
    <v-card-text>
      <p class="text-h4 text--primary">
        RSS
      </p>
      <v-row
        v-if="RSSs"
        no-gutters
      >
        <v-col
          v-if="RSSs.length === 0"
          cols="12"
          sm="12"
          md="12"
          style="text-align: center"
        >
          <h3>No hay datos</h3>
        </v-col>
        <v-col
          v-for="(rss, index) in RSSs"
          :key="`${index}`"
          cols="12"
          sm="12"
          md="12"
        >
          <v-card
            class="pa-2"
            outlined
            tile
          >
            <v-row
              no-gutters
            >
              <v-col
                cols="12"
                sm="2"
                md="2"
                style="padding-top: 10px;"
              >
                <v-icon v-if="rss.IsUsed" color="green">mdi-check-bold</v-icon>
                <v-icon v-else color="red">mdi-close-thick</v-icon>
                {{rss.Name}}
              </v-col>
              <v-col
                cols="12"
                sm="10"
                md="10"
              >
                <url-info
                  :url="rss.URL"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import apiBrightsign from '@/services/apiBrightsign'
import UrlInfo from './UrlInfo'
export default {
  components: {
    UrlInfo,
  },
  props: {
    presentationID: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    RSSs: null,
  }),
  watch: {
    presentationID () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    handleGetData () {
      this.RSSs = null
      if (!this.presentationID) return
      apiBrightsign.getAll(`/v1/private/presentations/${this.presentationID}/rss`)
        .then(response => {
          this.RSSs = response
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    },
  },
}
</script>

