<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="490"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('internalMaps.point.point', locale)}}
        </v-card-title>
        <v-card-text v-if="editedItem">
          <v-layout wrap>
            <v-flex xs3 style="padding: 10px 20px 10px 0;">
              <v-text-field
                v-model="editedItem.Pos"
                :label="$t('internalMaps.point.pos', locale)"
                outlined
                hideDetails
                @input="handleChangeIsModified"
              />
            </v-flex>
            <v-flex xs9 style="padding: 10px;">
              <translate
                v-model="editedItem.Title"
                :languages="languages"
                :label="$t('internalMaps.point.title', locale)"
                @input="handleChangeIsModified"
                type="text"
              />
            </v-flex>
            <v-flex xs12 
              v-if="categories"
              style="padding: 10px 0px 0px 0;"
            >
              <v-select
                v-model="editedItem.CategoryID"
                :items="categories"
                :label="$t('dtouch.interactiveMaps.point.category', locale)"
                outlined
                @change="handleChangeIsModified"
                itemText="Name"
                itemValue="ID"
                hide-details
              />
            </v-flex>
            <v-flex xs12 style="padding: 10px;">
              <translate
                v-model="editedItem.Description"
                :languages="languages"
                :label="$t('internalMaps.point.description', locale)"
                @input="handleChangeIsModified"
                type="text"
              />
            </v-flex>
            <v-flex xs12 style="padding: 10px;">
              <translate
                v-model="editedItem.Info"
                :languages="languages"
                :label="$t('internalMaps.point.info', locale)"
                @input="handleChangeIsModified"
                type="richText"
              />
            </v-flex>
            <v-flex xs6 style="padding: 20px 0 10px 0;">
              <color-picker
                :iconBGAndText="true"
                :primaryColor="editedItem.BGColor"
                :secondaryColor="editedItem.Color"
                :onChangeColor="handleChangeColor"
              />
            </v-flex>
            <v-flex xs6 style="padding: 10px 0px 0px 0;">
              <v-select
                v-model="editedItem.Scale"
                :items="[
                  -0.7, -0.5, -0.2, 1, 1.2, 1.5, 1.7
                ]"
                :label="$t('internalMaps.point.scale', locale)"
                outlined
                @change="handleChangeIsModified"
              />
              <!--v-text-field
                v-model="editedItem.Scale"
                outlined
                hideDetails
                :label="$t('internalMaps.point.scale', locale)"
                @input="handleChangeIsModified"
              /-->
            </v-flex>
            <v-flex xs12 style="padding: 10px 0;">
              <select-image 
                :image="editedItem.Image"
                :onChange="handleChangeImage"
                :locale="locale"
                :folder="workspaceID"
              />
              <p style="margin-top: 10px;"><small style="font-style:italic;">490x490</small></p>
            </v-flex>
          </v-layout>
        </v-card-text>
          <action-buttons
            v-if="editedItem"
            :id="editedItem.ID"
            :onDeleteItem="handleDelete"
            :onSaveItem="handleSave"
            :onClose="handleClose"
            :locale="locale"
          />
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import utilsService from '@/services/utils'
import Translate from '@/components/Translate'
import ActionButtons from '@/components/ActionButtons'
import SelectImage from '@/components/selectImage/Index'
import ColorPicker from '@/components/ColorPickerPrimarySecondary'
export default {
  components: {
    ActionButtons,
    Translate,
    ColorPicker,
    SelectImage,
  },
  props: {
    value: {
      type: Object,
      default:  null,
    },
    categories: {
      type: Array,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    onSave: {
      type: Function,
      required: true,
    },
    onDelete: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    editedItem: null,
  }),
  computed: {
    workspaceID () {
      return utilsService.getWorkspace('dtouch')
    },
  },
  watch: {
    value () {
      this.prepareValue()
    },
  },
  methods: {
    handleChangeImage (v) {
      if (!v) return 
      this.editedItem.Image = v.base64
      this.editedItem.newImage = v
    },
    handleChangeColor (bgColor, color) {
      this.editedItem.BGColor = bgColor
      this.editedItem.Color = color
    },
    prepareValue () {
      this.editedItem = this.value ? JSON.parse(JSON.stringify(this.value)) : null
      this.dialog = this.value ? true : false
    },
    handleChangeIsModified () {
    }, 
    handleDelete () {
      this.onDelete(this.editedItem.ID)
    },
    handleSave () {
      this.onSave(this.editedItem)
    },
    handleClose () {
      this.onClose(false)
    },
  },
}
</script>

