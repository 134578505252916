<template>
  <small>
  <v-btn v-if="sync" @click="handleSync(sync)" :disabled="loading" :loading="loading" color="orange">sync</v-btn><small style="margin: 0 20px;color: #CCCCCC;">{{ convertDate(value) }} <b>{{test(value)}}</b></small>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ msg }}
    </v-snackbar>
  </small>
</template>
<script>
import apiBrightsign from '@/services/apiBrightsign'
export default {
  props: {
    networkID: {
      type: String,
      required: true,
    },
    typeList: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    value: null,
    sync: null,
    msg: '',
    snackbar: false,
    timeout: 2000,
  }),
  watch: {
    typeList () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    convertDate (v) {
      if (!v) return ''
      return v.toISOString().replace('T', ' ').split('.').shift()
    },
    test(v) {
      let result = ''
      if (!v ) return result
      let aux = new Date() - v
      aux = (aux / 1000) | 0
      const d = (aux / 60 / 60 / 24) | 0
      if (d > 0) {
        aux = aux - (d * 60 * 60 * 24)
        result += `${d}d. `
      }
      const h = (aux / 60 / 60) | 0
      if (h > 0) {
        aux = aux - (h * 60 * 60)
        result += `${h}h. `
      }
      const m = (aux / 60 ) | 0
      if (m > 0) {
        aux = aux - (m * 60)
        result += `${m}m. `
      }
      const s = aux
      result += `${s}s. `
      return ` ( ${result})`
    },
    handleGetData () {
      this.value = null
      apiBrightsign.put(`/v1/private/cron/last-sync-date`, { type: this.typeList, networkID: this.networkID })
        .then(response => {
          this.value = response.data && response.data.LMU ? new Date(response.data.LMU) : null
          this.sync = response.data && response.data.SYNC ? response.data.SYNC : null
        })
        .catch(error => {
          console.log(error)
        })
    },
    handleSync (v) {
      this.loading = true
      apiBrightsign.put(v, {  })
        .then(() => {
          this.loading = false
          this.handleGetData()
          this.msg = 'completed...'
          this.snackbar = true
        })
        .catch(error => {
          this.loading = false
          console.log(error)
        })
    },
  },
}
</script>

