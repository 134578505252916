<template>
  <!--v-dialog
    v-model="dialog"
    max-width="800px"
  -->
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
      >
        {{ $t('common.newItem', locale) }}
      </v-btn>
    </template> -->
    <v-card v-if="dialog" elevation="0">
    <v-toolbar
      elevation="0"     
    >
      <v-icon large style="margin-right:10px;">mdi-account-multiple</v-icon>
      <v-toolbar-title>
        {{ $t('sysUser.title', locale) }}
      </v-toolbar-title>
    </v-toolbar>
      <!--v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title-->

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="8"
            >
              <v-text-field
                v-bind="{ 'outlined' : true}"
                v-model="editedItem.Name"
                :label="$t('sysUser.name', locale)"
                hide-details
              />
                <small>
                  {{editID}}
                </small>
              <v-text-field
                style="margin-top:20px"
                v-bind="{ 'outlined' : true}"
                v-model="editedItem.Password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                :label="$t('sysUser.password', locale)"
                @click:append="showPassword = !showPassword"
                hide-details
              />
              <v-text-field
                style="margin-top:20px"
                v-bind="{ 'outlined' : true}"
                v-model="editedItem.Email"
                type="text"
                :label="$t('sysUser.email', locale)"
                hide-details
              />
            </v-col>
            <!--v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                v-bind="{ 'outlined' : true}"
                v-model="editedItem.Password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                :label="$t('sysUser.password', locale)"
                @click:append="showPassword = !showPassword"
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                v-bind="{ 'outlined' : true}"
                v-model="editedItem.Email"
                type="text"
                :label="$t('sysUser.email', locale)"
                hide-details
              />
            </v-col-->
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <avatar 
                :size="{ w: 100, h: 100}"
                :oldImg="editedItem.Avatar"
                @change="handleChangeAvatar"
                style="margin: 0px;"
              />
            </v-col>
          </v-row>
          <v-card
            style="margin-top: 10px;padding: 20px"
          >
            <small>
              Entornos:
            </small>
            <v-row>
              <v-col
                cols="12"
                sm="4"
                md="4"
                lg="3"
              >
                <v-checkbox
                  v-model="editedItem.Environments"
                  label="user"
                  value="user"
                  multiple
                  hide-details
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="4"
                lg="3"
              >
                <v-checkbox
                  v-model="editedItem.Environments"
                  label="dviewcontent"
                  value="dviewcontent"
                  multiple
                  hide-details
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
                lg="3"
              >
                <v-checkbox
                  v-model="editedItem.Environments"
                  label="dtouch"
                  value="dtouch"
                  multiple
                  hide-details
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
                lg="3"
              >
                <v-checkbox
                  v-model="editedItem.Environments"
                  label="dfiles"
                  value="dfiles"
                  multiple
                  hide-details
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
                lg="3"
              >
                <v-checkbox
                  v-model="editedItem.Environments"
                  label="system"
                  value="sys"
                  multiple
                  hide-details
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
                lg="3"
              >
                <v-checkbox
                  v-model="editedItem.Environments"
                  label="queue"
                  value="queue"
                  multiple
                  hide-details
                />
              </v-col>            
              <v-col
                cols="12"
                sm="4"
                lg="3"
              >
                <v-checkbox
                  v-model="editedItem.Environments"
                  label="player"
                  value="player"
                  multiple
                  hide-details
                />
              </v-col>            
              <v-col
                cols="12"
                sm="4"
                lg="3"
              >
                <v-checkbox
                  v-model="editedItem.Environments"
                  label="booking"
                  value="booking"
                  multiple
                  hide-details
                />
              </v-col>            
              <v-col
                cols="12"
                sm="4"
                lg="3"
              >
                <v-checkbox
                  v-model="editedItem.Environments"
                  label="turismo"
                  value="turismo"
                  multiple
                  hide-details
                />
              </v-col>            
              <v-col
                v-if="isDevelopment || editID === 'fcc82081-173b-4679-bc3b-dc26c61f9f3c'"
                v-show="su"
                cols="12"
                sm="4"
                lg="3"
              >
                <v-checkbox
                  v-model="editedItem.Environments"
                  label="status"
                  value="status"
                  multiple
                  hide-details
                />
              </v-col>            
              <v-col
                v-show="su"
                cols="12"
                sm="4"
                lg="3"
              >
                <v-checkbox
                  v-model="editedItem.Environments"
                  label="status2"
                  value="status2"
                  multiple
                  hide-details
                />
              </v-col>            
              <v-col
                cols="12"
                sm="4"
                lg="3"
              >
                <v-checkbox
                  v-model="editedItem.Environments"
                  label="meteo"
                  value="meteo"
                  multiple
                  hide-details
                />
              </v-col>            
              <!--v-col
                cols="12"
                sm="4"
                lg="3"
              >
                <v-checkbox
                  v-model="editedItem.Environments"
                  label="Preparar URL"
                  value="prepareURL"
                  multiple
                  hide-details
                />
              </v-col-->            

          </v-row>
        </v-card>
        <player-totem-task
          v-if="editedItem && editedItem.Environments && editedItem.Environments.indexOf('player') >= 0"
          style="margin-top:20px;"
          :value="editedItem.TotemTask"
          :onChange="(v) => { editedItem.TotemTask = v}"
        />
        <brightsign-permissions
          v-if="editedItem && editedItem.Environments && editedItem.Environments.indexOf('player') >= 0"
          style="margin-top:20px;"
          :value="editedItem.BrightsignPermissions"
          :onChange="(v) => { editedItem.BrightsignPermissions = v}"
        />
          <!--v-row v-if="editedItem && editedItem.Environments">
            <v-col
              cols="12"
              sm="12"
            >
              <v-expansion-panels>
                <dtouch-permissions
                  v-if="editedItem.Environments.indexOf('dtouch') >= 0"
                  :onChange="handleChangeDtouchPermissions"
                />
              </v-expansion-panels>
            </v-col>
          </v-row-->
        </v-container>
      </v-card-text>

      <action-buttons
        :id="editedItem.ID"
        :onDeleteItem="handleDeleteItem"
        :onSaveItem="handleSave"
        :onClose="handleClose"
        :locale="locale"
      />
    </v-card>
  <!--/v-dialog-->
</template>

<script>
  import * as sha1 from 'js-sha1'
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import ActionButtons from '@/components/ActionButtons'
  import Avatar from '@/components/avatar/Index'
  import PlayerTotemTask from './PlayerTotemTask'
  import BrightsignPermissions from './BrightsignPermissions'
  export default {
    components: { 
      ActionButtons,
      Avatar,
      PlayerTotemTask,
      BrightsignPermissions,
      //DtouchPermissions,
    },
    props: {
      editID: {
        type: String,
        required: false,
        default: null
      },
      dialog: {
        type: Boolean,
        required: false,
        default: false
      },
      onGetData: {
        type: Function,
        required: true
      },
      onCloseDialog: {
        type: Function,
        required: true
      }
    },
    data: () => ({
      formTitle: '',
      defaultItem: {
        Name: '',
        Environments: [],
        Password: null,
        Avatar: null,
        newMedia: null,
      },
      editedItem: {},
      showPassword: false
    }),
    computed: {
      ...mapState('app',['locale']),
      isDevelopment() {
        return process.env.VUE_APP_ENV === 'development'
      },
      su () {
        return this.$route.query.su === 'true'
      },
    },
    watch: {
      dialog (v) {
        if (v) 
          if (this.editID) this.handleGetItem (this.editID)
          else this.editedItem = this.defaultItem
      }
    },
    methods: {
      handleGetItem(id) {
        api.getItem ('system', 'v1/system/users/', id)
          .then(response => {   
            this.editedItem = response
          })
      },
      handleChangeAvatar (v) {
        this.editedItem.newMedia = v
      },
      /*
      handleChangeDtouchPermissions (v) {
        this.editedItem.dtouchPermissions = v
      },
      */
      handleClose () {
        this.onCloseDialog()
      },
      handleDeleteItem (v) {
        if (v) 
          api.deleteItem ('system', 'v1/system/users/', v)
          .then(() => {                      
            this.onGetData()
            this.onCloseDialog()
          })
      },
      handleSave () {
        if (this.editedItem.Password && this.editedItem.Password.length > 0) this.editedItem.Password = sha1(this.editedItem.Password)
        if (this.editedItem.ID)
          api.updateItem ('system', 'v1/system/users/', this.editedItem.ID, this.editedItem)
          .then(() => {          
            this.onGetData()
            this.onCloseDialog()
          })  
        else
          api.addItem ('system', 'v1/system/users/', this.editedItem)
          .then(() => {          
            this.onGetData()
            this.onCloseDialog()
          })        
      }
    },
  }
</script>

