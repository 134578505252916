<template>
  <v-row style="background: #CCCCCC80;padding: 5px;">
    <v-col
      cols="12"
      sm="12"
    >
      <v-text-field
        :label="$t('dtouch.interactiveMaps.categories.icon', locale).concat(' (ej: mdi-plus)')"
        v-model="icon"
        outlined
        hide-details
        @input="handleChange"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
    >
      <translate
        v-model="name"
        :languages="languages"
        :label="$t('dtouch.interactiveMaps.categories.name', locale)"
        @input="handleChange"
        type="text"
        style="margin: 0;"
      />
    </v-col>
  </v-row>
</template>
<script>
import Translate from '@/components/Translate'
export default {
  components: {
    Translate,
  },
  props: {
    editedItem: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    onChange: {
      type: Function,
      requried: true,
    },
  },
  data: () => ({
    id: null,
    name: {es: ''},
    icon: null,
    notificationMails: null,
    messageConfirm: {es: 'Registro creado correctamente'},
    config: [],
  }),
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      this.id = this.editedItem.ID
      this.name = JSON.parse(JSON.stringify(this.editedItem.Name))
      this.icon = this.editedItem.Icon
    },
    handleChangeFields (v) {
      for (let i = 0; i < this.config.length; i++) {
        if (this.config[i].id === v.id) this.config[i] = v
      }
      this.handleChange()
    },
    handleChange () {
      this.onChange({
        ID: this.id,
        Name: this.name,
        Icon: this.icon
      })
    },
  },
}
</script>


