<template>
  <v-layout wrap>
    <!--v-flex xs12>
      <test-stripe style="margin-bottom: 2000px;"/>
    </v-flex-->
    <v-flex xs12 md12 style="padding: 20px 0;">
      <cajasiete-status />
    </v-flex>
    <v-flex xs12 md4 style="padding: 20px 0;">
      <activity-to-image />
    </v-flex>
    <v-flex 
      v-if="sslStatus"
      xs12 md4 style="padding:20px">
      <center style="padding: 10px;background-color:#CCC;"
              :style="{ color: getIntervalInHours(sslStatus.LMD) > 24 ? 'red' : 'black' }"
      >
      <h1>
        {{sslStatus.LMD.toString().split('GMT').shift()}}        [{{(getIntervalInHours(sslStatus.LMD))}}h.]
      </h1>
      </center>
      <v-layout wrap
        v-for="(item, index) of sslStatus.data"
        :key="index"
        :style="{ color: item.days < 100 ? item.days < 30 ? 'red' : 'orange' : 'black' }"
        style="border-bottom: 1px solid #CCCCCC80;"
      >
        <v-flex xs8 style="padding:10px;">
          {{prepareDomain(item.domain)}}
        </v-flex>
        <v-flex xs4 style="padding:10px;">
          <center>
            {{ item.days }} d.
          </center>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12 md4 style="padding: 20px 0;">
      <ActtivResult />
      <BrightsignResult />
    </v-flex>
    <v-flex xs12 md4 style="padding: 20px 0;">
      <RSSDviewContent />
    </v-flex>
    <v-flex xs12 md4 style="padding: 20px;">
      <meteo-result />
    </v-flex>
    <v-flex xs12 md4 style="padding: 20px;">
      <menus />
    </v-flex>
    <v-flex xs12>
      <dtouch-stats-force />
    </v-flex>

  </v-layout>
</template>
<script>
import axios from 'axios'
import Menus from './Menus'
import RSSDviewContent from './RSSDviewContent'
import MeteoResult from './MeteoResult'
import ActivityToImage from './ActivityToImage'
import DtouchStatsForce from './dtouchStatsForce/Index'
import ActtivResult from './ActtivResult'
import BrightsignResult from './BrightsignResult'
import CajasieteStatus from './CajasieteStatus'
//import TestStripe from './stripe/Index'
export default {
  components: {
    Menus,
    RSSDviewContent,
    MeteoResult,
    ActivityToImage,
    DtouchStatsForce,
    ActtivResult,
    BrightsignResult,
    CajasieteStatus,
    //TestStripe,
  },
  data: () => ({
    sslStatus: null
  }),
  mounted () {
    this.handleGetSSLStatus()
  },
  methods: {
    getIntervalInHours (v) {
      return (((new Date() - v) / 60000)/60)|0
    },
    prepareDomain (v) {
      return v.split('/').pop().replace('www.', '')
    },
    handleGetSSLStatus () {
      const url = 'https://system.dtouchwebapp.com/v1/local/config/ssl?token=32de3ec5-3fbd-4064-89d4-2123d392b198'
      axios.get(url)
        .then(response => {
          response.data.LMD = new Date(response.data.LMD)
          response.data.data = response.data.data.sort((a, b) => {
            return a.days - b.days
          })
          this.sslStatus = response.data
        })
    },
  }
}
</script>


