<template>
  <v-card
    v-if="eventType === 'cardOn'"
    :key="contentTypeID"
    class="mx-auto"
    style="border-radius: 10px;cursor: pointer !important;"
    :elevation="3"
    :style="{ opacity:  data.old ? 0.4 : 1}"
  >
    <div :style="{ backgroundColor: color }">
      <v-img
        :src="image"
        aspect-ratio="16/9"
        cover
        :style="{ height: smallCard ? '116px' : null }"
        style="width: 100%;"
      />
    </div>

    <v-card-title 
      color="red"
      :style="{ color: activityColor1 ? activityColor1 : color1 }"
    >
      <div class="time"
        :style="{ 
          top: smallCard ? '103px' : `${180}px`, 
          backgroundColor: 'blue' }"
      >
        <v-icon class="time-icon" dark style="font-size:0.9rem">mdi-clock-time-five-outline</v-icon>
        <span v-if="data.AllTimes">Todo el día</span>
        <span v-if="!data.AllTimes">{{ time }} {{ '-' }} {{ timeEnd}}</span>
      </div>
      <b>
        <v-tooltip bottom v-if="data.Recurrent">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="black"
              dark
              v-bind="attrs"
              v-on="on"
            >
              mdi-book-sync
            </v-icon>
          </template>
          <span>Actividad recurrente</span>
        </v-tooltip>
        {{ getTranslation(title, locale) }}{{data.HideEvent ? ' [oculto]' : ''}}
      </b>
    </v-card-title>

    <v-card-subtitle>
      <p style="margin:0;" v-if="detail">
        {{ getTranslation(detail, locale) }}
      </p>
      <p style="margin: 0px 0 0 0;">
        {{ getTranslation(subtitle, locale) }}
      </p>
    </v-card-subtitle>
    <!--more-info
      v-if="data.showMoreInfo"
      :locale="locale"
      :event="data"
    /-->
  </v-card>
  <v-alert
    v-else
    :color="color"
    dark
    style="cursor: pointer !important;margin:0;"
  >
    <div class="text-h6">
        <v-tooltip bottom v-if="data.Recurrent">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="white"
              dark
              v-bind="attrs"
              v-on="on"
            >
              mdi-book-sync
            </v-icon>
          </template>
          <span>Actividad recurrente</span>
        </v-tooltip>
      {{ getTranslation(title, locale) }}{{data.HideEvent ? ' [oculto]' : ''}}
    </div>
    <div>
      <v-icon class="time-icon" dark style="font-size:0.9rem">mdi-clock-time-five-outline</v-icon>
      <span v-if="data.AllTimes">Todo el día</span>
      <span v-if="!data.AllTimes">{{ time }} {{ '-' }} {{ timeEnd}}</span>
    </div>
  </v-alert>
</template>

<script>
import utils from '@/services/utils'
//import MoreInfo from './MoreInfo.vue'
export default {
  components: {
    //MoreInfo,
  },
  name: 'Event',
  props: {
    contentTypeID: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      default: null,
    },
    color1: {
      type: String,
      default: '#1a1a1a'
    },
    color2: {
      type: String,
      default: '#cc2526'
    },
    locale: {
      type: String,
      required: true,
    },
    showList: {
      type: Array,
      required: true,
    },
    eventType: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      color: null,
      image: null,
      time: null,
      timeEnd: null,
      title: null,
      subtitle: null,
      detail: null,
      active: false,
      width: 230,
      height: 132,
      activityColor1: null,
      activityColor2: null,
    }
  },
  computed: {
    smallCard () {
      return true//116
    },
  },
  watch: {
    'contentTypeID' () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    getTranslation (v, locale) {
      if (!v) return ''
      if (v[locale]) return v[locale]
      if (v['es']) return v['es']
      return v[0]
    },
    prepareData () {
      const aux = this.showList.filter(item => item.ID === this.data.ManagerActivityID).shift()
      if (aux) {
        const category = null//this.contentTypeData.Categories.filter(x => x.ID === aux.CategoryID).shift()
        this.image = aux.Image ? aux.Image : category ? category.Image : null
        this.color = null//category && category.Color ? category.Color : this.contentTypeData.Color
        this.time = this.data.Time ? utils.convertTime(this.data.Time).split(' ').join('') : null
        this.timeEnd = this.data.TimeEnd ? utils.convertTime(this.data.TimeEnd).split(' ').join('') : null
        this.title = aux.Title
        this.detail = aux.Place
        this.subtitle = category ? category.Name : null
        this.active = this.data.active ? true : false
        this.activityColor1 = this.data.color1
        this.activityColor2 = this.data.color2
      } else {
        const category = null//this.contentTypeData.Categories.filter(x => x.ID === this.data.CategoryID).shift()
        this.image = this.data.Image ? this.data.Image : category ? category.Image : null
        this.color = null//category && category.Color ? category.Color : this.contentTypeData.Color
        this.time = this.data.Time ? utils.convertTime(this.data.Time).split(' ').join('') : null
        this.timeEnd = this.data.TimeEnd ? utils.convertTime(this.data.TimeEnd).split(' ').join('') : null
        this.title = this.data.Title
        this.detail = this.data.Place
        this.subtitle = category ? category.Name : null
        this.active = this.data.active ? true : false
        this.activityColor1 = this.data.color1
        this.activityColor2 = this.data.color2
      }
    },
  },
}
</script>

<style scoped>
.time {
  position: absolute;
  height: 25px;
  margin-left: -16px;
  width: 150px;
  border-radius: 0 30px 30px 0;
  padding: 0 0 0 35px;
  font-size: 0.8rem !important;
  color: white;
  line-height: 24px;
}
.time-icon {
  margin-right: 6px;
  color: white;
  margin-top: -2px !important;
}
.v-card-title {
  font-size: 16px;
  min-height: 30px;
  line-height: 1;
  padding: 22px 0 0 15px !important;
}
.v-card-subtitle {
  font-size: 10px;
  line-height: 1;
  padding: 0 0 20px 15px !important;
}
</style>

