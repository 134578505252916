<template>
  <div>
    <preview-presentation
      v-if="templates && templateID && zones"
      :templates="templates"
      :templateID="templateID"
      locale="es"
      :zones="zones"
      bgColor="#FFFFFF"
    />
  </div>
</template>
<script>
import api from '@/services/api'
import PreviewPresentation from './Preview'
export default {
  components: {
    PreviewPresentation,
  },
  data: () => ({
    templates: null,
    templateID: null,
    zones: null,
  }),
  computed: {
    workspaceID () {
      return this.$route.params.workspace
    },
    presentationID () {
      return this.$route.params.presentation
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    handleGetData () {
      if (!this.workspaceID || !this.presentationID) return
      const url = 'v1/public/presentations/'
      api.getItem ('players', url, `${this.workspaceID}/${this.presentationID}`)
        .then(response => {
          if (!response) return
          this.templates = response.templates
          this.zones = response.zones
          this.templateID = response.templateID
        })
    },
  },
}
</script>
