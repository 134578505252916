<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('dtouch.workspace.foodDrink.title', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row v-if="foodDrinkData" style="padding: 20px 0;">
        <v-col
          v-if="orderSettingsNotSaved"
          cols="12"
          sm="12"
          style="padding: 20px 0 0 0;"
          class="order"
        >
          <v-alert
            border="right"
            colored-border
            type="error"
            elevation="2"
            dense
          >
            Order settings not saved.
          </v-alert>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          style="padding-top: 10px;"
          class="order"
        >
          <v-switch
            v-model="foodDrinkData.FoodDrinkAllowOrders"
            :label="$t('dtouch.dishOrders.allowOrders', locale)"
            @change="handleChangeData"
            hide-details
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          style="padding-top: 10px;"
          class="order"
        >
          <color-picker-primary-secondary
            v-if="foodDrinkData.FoodDrinkAllowOrders"
            style="width: 100px;"
            :primaryColor="ordersBGColor"
            :secondaryColor="ordersTextColor"
            :onChangeColor="handleChangeOrdersColors"
          />

        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="padding-top: 10px;"
          class="order"
        >
          <translate
            v-if="foodDrinkData.FoodDrinkAllowOrders"
            v-model="ordersNote"
            :languages="languages"
            :label="$t('dtouch.dishOrders.note', locale)"
            @input="handleChangeData"
            type="text"
            style="margin: 0;"
          />
        </v-col>
        <v-col
          v-if="foodDrinkData.FoodDrinkAllowOrders"
          cols="12"
          sm="6"
          class="order"
        >
          <v-switch
            v-model="ordersFixCart"
            :label="$t('dtouch.dishOrders.FixCart', locale)"
            @change="handleChangeData"
            hide-details
            style="margin-top: 0;padding-top: 0;"
          />
        </v-col>
        <v-col
          v-if="foodDrinkData.FoodDrinkAllowOrders"
          cols="12"
          sm="6"
          class="order"
        >
          <v-switch
            v-model="ordersAllInclusive"
            :label="$t('dtouch.dishOrders.allInclusive', locale)"
            @change="handleChangeData"
            hide-details
            style="margin-top: 0;padding-top: 0;"
          />
        </v-col>

        <v-col
          v-if="foodDrinkData.FoodDrinkAllowOrders"
          cols="12"
          sm="12"
          style="padding-top: 10px;"
          class="order"
        >
          <v-text-field
            :label="$t('dtouch.dishOrders.formConfirm.workspaceLabel', locale)"
            outlined
            v-model="ordersFormConfirm"
            @change="handleChangeData"
            hide-details
          />
            <small>Ej: <span style="color: #00000080;">
              fullName,room,email,paymentMethods
              </span>
            </small>
        </v-col>
        <v-col
          v-if="foodDrinkData.FoodDrinkAllowOrders"
          cols="12"
          sm="12"
          class="order"
        >
          <v-text-field
            :label="$t('dtouch.dishOrders.notificationMails', locale)"
            outlined
            v-model="ordersNotificationMails"
            @change="handleChangeData"
            hide-details
          />
            <small>Ej: <span style="color: #00000080;">
              test@dg.com;test1@dg.com
              </span>
            </small>
        </v-col>
        <v-col
          v-if="foodDrinkData.FoodDrinkAllowOrders"
          cols="12"
          sm="12"
          style="padding-top: 10px;"
          class="order"
        >
          <v-text-field
            :label="$t('dtouch.dishOrders.percentage', locale)"
            outlined
            v-model="ordersPercentage"
            @change="handleChangeData"
            hide-details
          />
        </v-col>
        <v-col
          v-if="foodDrinkData.FoodDrinkAllowOrders"
          cols="12"
          sm="12"
          class="order"
        >
          <v-switch
            v-model="ordersIncludeExtras"
            :label="$t('dtouch.dishOrders.includeExtras', locale)"
            @change="handleChangeData"
            hide-details
          />
        </v-col>
        <v-col
          v-if="foodDrinkData.FoodDrinkAllowOrders"
          cols="12"
          sm="12"
          style="padding-top: 10px;background-color:#ff000080;"
          class="order"
        >
          <small style="font-weight: 700;">Twilio-config:</small>
          <v-text-field
            style="margin-top:10px;"
            label="SMSFrom"
            outlined
            v-model="ordersSmsFrom"
            @change="handleChangeData"
            hide-details
          />
          <small style="opacity: 50%;">Ej: +13123123123</small>
          <v-text-field
            style="margin-top: 10px;"
            label="SMSTo"
            outlined
            v-model="ordersSmsTo"
            @change="handleChangeData"
            hide-details
          />
          <small style="opacity:50%;">Ej: +34123123123</small>
        </v-col>

        <v-col
          v-if="foodDrinkData.FoodDrinkAllowOrders"
          cols="12"
          sm="12"
          style="padding-top: 10px;"
          class="order"
        >
          <translate
            v-if="foodDrinkData.FoodDrinkAllowOrders"
            v-model="ordersTerms"
            :languages="languages"
            :label="$t('dtouch.dishOrders.terms', locale)"
            @input="handleChangeData"
            type="textArea"
            style="margin: 0;"
          />
        </v-col>


        <v-col
          cols="12"
          sm="12"
          style="padding: 0"
        >
          <v-switch
            v-model="foodDrinkData.ShowDishesImage"
            :label="$t('dtouch.workspace.foodDrink.showDishesImage', locale)"
            @change="handleChangeData"
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="padding: 0"
        >
          <v-switch
            v-model="foodDrinkData.ShowDishesAllergens"
            :label="$t('dtouch.workspace.foodDrink.showDishesAllergens', locale)"
            @change="handleChangeData"
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="padding: 0"
        >
          <v-switch
            v-model="foodDrinkData.FoodIconRight"
            :label="$t('dtouch.workspace.foodDrink.foodIconRight', locale)"
            @change="handleChangeData"
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="padding: 10px 0 0 0;"
        >
          <color-picker-primary-secondary
            style="width: 100px;"
            :primaryColor="foodDrinkData.FoodDrinkBGColor"
            :secondaryColor="foodDrinkData.FoodDrinkTextColor"
            :onChangeColor="handleChangeColors"
            :label="$t('dtouch.workspace.foodDrink.foodDrinkColor', locale)"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="padding: 10px 0 0 0;"
        >
          <v-autocomplete
            v-model="foodDrinkData.AllergenIconType"
            :disabled="loading"
            :items="allergenIconTypes"
            filled
            chips
            :label="$t('dtouch.workspace.foodDrink.allergenIconType', locale)"
            item-text="name"
            item-value="id"
            hide-details
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="remove(data.item)"
              >
                <v-avatar left>
                  <v-img :src="data.item.avatar"></v-img>
                </v-avatar>
                {{ data.item.name }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar>
                  <img :src="data.item.avatar">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="padding: 0"
        >
          <v-switch
            v-model="foodDrinkData.FoodDrinkOrderByLocale"
            label="Ordenar por idioma"
            @change="handleChangeData"
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="padding: 10px 0 0 0;"
        >
          <v-btn
            block
            color="primary"
            @click="handleExternalPDF"
          >
            {{ $t('dtouch.workspace.foodDrink.makeExternalPDF', locale) }}
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
import apiDishOrders from '@/services/apiDishOrders'
import ColorPickerPrimarySecondary from '@/components/ColorPickerPrimarySecondary'
import Translate from '@/components/Translate'
export default {
  name: 'FoodDrinkForm',
  components: {
    ColorPickerPrimarySecondary,
    Translate,
  },
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
    languages: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    orderSettingsNotSaved: false,
    foodDrinkData: null,
    loading: true,
    allergenIconTypes: [],
    ordersBGColor: '#FFFFFF',
    ordersTextColor: '#000000',
    ordersNote: null,
    ordersFixCart: null,
    ordersFormConfirm: null,
    ordersPercentage: null,
    ordersIncludeExtras: null,
    ordersAllInclusive: null,
    ordersTerms: null,
    ordersSmsFrom: null,
    ordersSmsTo: null,
    ordersNotificationMails: null,
  }),
  methods: {
    getAllergensTypes () {
      this.loading = true
      api.getAllWithoutLimit('dtouch', `v1/system/constants/allergens-types`)
        .then(response => {
          this.allergenIconTypes = response.map(item => {
            return {
              id: item.Type,
              name: item.Type,
              avatar:`https://www.dtouch.es/assets/allergens/${item.Type !== 'default' ? item.Type.concat('/') : ''}1.png`,
            }
          })
          this.loading = false
        })
    },
    handleGetOrdersConfig () {
      apiDishOrders.getConfig (this.workspaceID)
        .then(response => {
          this.ordersNote = response && response.Note ? JSON.parse(response.Note) : { es: '' }
          this.ordersBGColor = response && response.BGColor ? response.BGColor : '#FFFFFF'
          this.ordersTextColor = response && response.TextColor ? response.TextColor : '#000000'
          this.ordersIncludeExtras = response && response.IncludeExtras
          this.ordersFixCart = response && response.FixCart
          this.ordersFormConfirm = response ? response.FormConfirm : null
          this.ordersAllInclusive = response && response.AllInclusive
          this.ordersPercentage = response ? response.Percentage : null
          this.ordersTerms = response ? JSON.parse(response.Terms) : null
          this.ordersSmsFrom = response ? response.TwilioSMSFrom : null
          this.ordersSmsTo = response ? response.TwilioSMSTo : null
          this.ordersNotificationMails = response ? response.NotificationMails : null
          this.loading = false
        })
    },
    getFoodDrinkData () {
      this.loading = true
      api.getItem('dtouch', `v1/system/workspaces/`, `${this.workspaceID}/food-drink`)
        .then(response => {
          if (response) this.foodDrinkData = response

          this.orderSettingsNotSaved = !this.foodDrinkData || this.foodDrinkData.FoodDrinkAllowOrders === null

          this.handleGetOrdersConfig()
        })
    },

    handleExpand () {
      if(!this.foodDrinkData && this.workspaceID) {
        this.getAllergensTypes ()
        this.getFoodDrinkData()
      }
    },
    handleChangeColors (bg, text) {
      this.foodDrinkData.FoodDrinkBGColor = bg
      this.foodDrinkData.FoodDrinkTextColor = text
      this.handleChangeData()
    },
    handleChangeOrdersColors (bg, text) {
      this.ordersBGColor = bg
      this.ordersTextColor = text
      this.foodDrinkData.FoodDrinkOrdersBGColor = bg
      this.foodDrinkData.FoodDrinkOrdersTextColor = text
      this.handleChangeData()
    },
    handleChangeData () {
      this.foodDrinkData.FoodDrinkOrderNote = this.ordersNote
      this.foodDrinkData.FoodDrinkOrdersBGColor = this.ordersBGColor
      this.foodDrinkData.FoodDrinkOrdersTextColor = this.ordersTextColor
      this.foodDrinkData.FoodDrinkOrdersIncludeExtras = this.ordersIncludeExtras
      this.foodDrinkData.FoodDrinkOrdersFixCart = this.ordersFixCart
      this.foodDrinkData.FoodDrinkOrdersFormConfirm = this.ordersFormConfirm
      this.foodDrinkData.FoodDrinkOrdersAllInclusive = this.ordersAllInclusive
      this.foodDrinkData.FoodDrinkOrdersPercentage = this.ordersPercentage ? parseFloat(this.ordersPercentage) : null
      this.foodDrinkData.FoodDrinkOrderTerms = this.ordersTerms
      this.foodDrinkData.FoodDrinkOrderTwilioSmsFrom = this.ordersSmsFrom
      this.foodDrinkData.FoodDrinkOrderTwilioSmsTo = this.ordersSmsTo
      this.foodDrinkData.FoodDrinkOrderNotificationMails = this.ordersNotificationMails

      this.onChangeData(this.foodDrinkData)
    },
    handleExternalPDF () {
      api.getItem ('dtouch', 'v1/system/dishes/', this.workspaceID)
        .then(() => {
        })
    },
  },
}
</script>
<style scoped>
.order {
  background-color: #CCCCCC80;
}
</style>

