<template>
  <div>
    <!--v-btn
      text 
      style="text-transform: none;font-family:Roboto;font-weight:400;letter-spacing: 0;"
      @click="handleGetURLInfo"
    >
      {{url}}
    </v-btn>
    <v-btn
      x-small
      color="gray"
      elevation="1"
      @click="handleCopy"
    >
      <v-icon x-small>mdi-content-copy</v-icon>
    </v-btn>
    <v-snackbar
      v-model="showAlert"
      timeout="1000"
    >
      Copiado!!!

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="showAlert = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar-->
    <v-dialog
      v-model="showInfo"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          URL Info
        </v-card-title>

        <v-card-text style="padding: 10px 50px;">
          <v-row
            v-if="info"
            no-gutters
          >
            <v-col
              v-for="(item, index) in info"
              :key="`${index}`"
              cols="12"
              sm="12"
            >
              <v-row
                v-if="item.value"
                no-gutters
              >
                <v-col
                  cols="12"
                  sm="3"
                >
                  <small style="color:#cccccc;">{{item.type.toUpperCase()}}:</small>
                </v-col>
                <v-col
                  cols="12"
                  sm="9"
                  :style="{ color: item.color ? item.color : 'gray' }"
                >
                  <small><b>{{item.value.toUpperCase()}}</b></small>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            text
            @click="onClose"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import apiBrightsign from '@/services/apiBrightsign'
export default {
  props: {
    url: {
      type: String,
      default: null,
    },
    onClose: {
      type: Function,
      requried: true,
    },
  },
  data: () => ({
    showInfo: false,
    info: null,
  }),
  watch: {
    url () {
      this.showInfo = this.url ? true : false
      if (this.url) this.handleGetURLInfo()
    },
  },
  methods: {
    handleGetURLInfo () {
      apiBrightsign.put(`/v1/private/presentations/url-info`, { url: this.url })
        .then(response => {
          this.info = response.data
          this.showInfo = true
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>


