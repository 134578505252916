<template>
  <v-layout wrap v-if="zones && zones.length > 0">
      <url-dialog 
        :editedItem="urlDialogData"
        :onClose="() => { urlDialogData = null}"
        :onSave="handleChangeURL"
        :locale="locale"
      />
    <v-flex 
      xs12 
      v-if="presentation.TemplateID === '140181d0-aeff-11ed-9c29-f933aac7d201'"
      style="background-color: #CCCCCC;padding: 10px; margin: 0 0 10px 0;border-radius: 20px;"
    >
      <small>{{ $t('players.template', locale) }}</small>
      <v-radio-group v-model="selectedMaintenance">
        <v-radio
          v-for="n in maintenances"
          :key="n.xml"
          :label="`${n.name}`"
          :value="n.xml"
        ></v-radio>
      </v-radio-group>
    </v-flex>
    <v-flex xs12 
      v-else
      v-for="(item, index) of zones"
      :key="index"
      style="background-color: #CCCCCC80;padding: 10px; margin: 0 0 10px 0;border-radius: 20px;"
    >
      <small style="padding-bottom:10px;">Zona {{index + 1}} :</small>
      <!--v-text-field
        style="margin: 10px 0;"
        v-model="item.XML"
        label="XML"
        hide-details
        outlined
        @input="handleChange"
      /-->
      <v-text-field
        outlined
        append-icon="mdi-square-edit-outline"
        hide-details
        style="margin: 10px 0;"
        v-model="item.XML"
        @click:append="handleEditUrlDialog(index, item)"
        label="XML / URL"
        @input="handleChange"
      />
      <color-picker
        label="Color FONDO"
        :value="item.sColor"
        :onChange="(v) => handleChangeColor(item, v)"
        borderRadius="20px 50 20 0"
      />
      <div style="background-color: #fff0da;padding: 10px;margin-top:10px;border-radius:10px;">
        <p>
          <small><b style="color: #CCCCCC;">Only</b> <b style="color: orange;">message</b>:</small>
        </p>
        <color-picker
          label="Color TEXTO"
          :value="item.sTextColor"
          :onChange="(v) => handleChangeTextColor(item, v)"
          borderRadius="20px 50 20 0"
        />
        <v-layout wrap>
          <v-flex xs6>
        <v-text-field
          style="margin: 10px 5px 0 0;"
          v-model="item.TextSize"
          label="Tama�o"
          hide-details
          outlined
          @input="handleChange"
        />
          </v-flex>
          <v-flex xs6>
        <v-text-field
          style="margin: 10px 0 0 5px;"
          v-model="item.TextVelocity"
          label="Velocidad"
          hide-details
          outlined
          @input="handleChange"
        />
          </v-flex>
        </v-layout>
      </div>
    </v-flex>
  </v-layout>
</template>
<script>
import ColorPicker from '@/components/common-components/ColorPickerItem'
import UrlDialog from './urlDialog/Index'
export default {
  name: 'ZoneForm',
  components: {
    ColorPicker,
    UrlDialog,
  },
  props: {
    presentation: {
      type: Object,
      default: null,
    },
    templates: {
      type: Array,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    zones: [],
    selectedMaintenance: null,
    urlDialogData: null,
  }),
  computed: {
    maintenance () {
      return '140181d0-aeff-11ed-9c29-f933aac7d201'
    },
    maintenances () {
      return [{
        xml: 'https://cm-player-api.dtouchwebapp.com/assets/mantenimiento1/index.xml',
        name: '1',
      },{
        xml: 'https://cm-player-api.dtouchwebapp.com/assets/mantenimiento2/index.xml',
        name: '2',
      }]
    },
  },
  watch: {
    selectedMaintenance () {
      this.handleChange()
    },
    'presentation.ID' () {
      this.prepareZones()
    },
    'presentation.TemplateID' (v) {
      if (v === this.maintenance && !this.selectedMaintenance) 
        this.selectedMaintenance = this.maintenances.map(item => item.id).shift()
      this.prepareZones()
    },
  },
  mounted () {
    this.prepareZones()
  },
  methods: {
    prepareZones () {
      if (this.presentation.TemplateID === this.maintenance) {
        const aux = this.presentation.Zones && this.presentation.Zones.length > 0 ? 
          this.presentation.Zones.map(item => item.XML).shift() : null
        if (this.maintenances.filter(x => x.xml === aux).length > 0) this.seectedMaintenance = aux
        else this.selectedMaintenance = this.maintenances.map(item => item.xml).shift()

        this.handleChange()
      } else {
        const aux = this.templates.filter(x => x.ID === this.presentation.TemplateID).shift()
        this.zones = []
        if (aux && aux.NumZones) {
          for ( let i = 0; i < aux.NumZones; i++) {
            const z = this.presentation.Zones.filter(x => x.Zone === i).map(item => {
              //console.log(item)
              return {xml: item.XML, color: item.Color, textColor: item.TextColor, textSize: item.TextSize, textVelocity: item.TextVelocity}
            }).shift()
            this.zones.push({ 
              Zone: i, 
              XML: z && z.xml ? z.xml : '', 
              Color: z && z.color ? z.color : 'FFFFFF', 
              sColor: `#${z && z.color ? z.color : 'FFFFFF'}`, 
              TextColor: z && z.textColor ? z.textColor : '000000', 
              sTextColor: `#${z && z.textColor ? z.textColor : '000000'}`, 
              TextSize: z && z.textSize ? z.textSize : 25, 
              TextVelocity: z && z.textVelocity ? z.textVelocity : 100, 
            })
          }
          this.handleChange()
        }
      }
    },
    handleChange () {
      if (this.presentation.TemplateID === this.maintenance) {
        this.Zone = [{
            Zone: 0,
            XML: this.selectedMaintenance,
            Color: 'FFFFFF',
            sColor: '#FFFFFF',
            TextColor: '000000',
            sTextColor: '000000',
            TextSize: 25,
            TextVelocity: 100,
          }]
      }
      this.onChange(this.zones)
    },
    handleChangeColor (item, v) {
      item.Color = v.replace('#','')
      this.handleChange()
    },
    handleChangeTextColor (item, v) {
      item.TextColor = v.replace('#','')
      this.handleChange()
    },
    handleEditUrlDialog (index, item) {
      this.urlDialogData = {index, item}
    },
    handleChangeURL (index, v) {
      this.zones[index].XML = v
      this.urlDialogData = null
    },
  },
}
</script>
