var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"20px"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsFiltered,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"ID","show-expand":"","items-per-page":-1,"hide-default-footer":true,"loading":_vm.loading},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.numGroups",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Groups ? item.Groups.length : '')+" ")]}},{key:"item.numRss",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.AutoPlayFile && item.AutoPlayFile.RSSs ? item.AutoPlayFile.RSSs.length : '')+" ")]}},{key:"item.numHtml",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.AutoPlayFile && item.AutoPlayFile.HTMLs ? item.AutoPlayFile.HTMLs.length : '')+" ")]}},{key:"item.CD",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDatetime(item.CD))+" ")]}},{key:"item.LMD",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDatetime(item.LMD))+" ")]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [(item.ProjectName && item.ProjectPath)?_c('v-btn',{attrs:{"color":"primary","x-small":""},on:{"click":function($event){return _vm.handleClickDownload(item.ProjectPath, item.ProjectName)}}},[_vm._v(" Descargar ")]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Grupos")]),_c('v-spacer'),_c('v-btn',{staticStyle:{"margin-right":"10px"},attrs:{"elevation":"2","loading":_vm.loading,"color":"primary","disabled":_vm.loading},on:{"click":_vm.handleGetData}},[_vm._v(" refresh ")]),_c('search',{staticStyle:{"margin-right":"10px"},attrs:{"onSearch":_vm.handleSearch,"showClear":true}}),_c('v-switch',{staticClass:"mt-2",attrs:{"label":"Single expand"},model:{value:(_vm.singleExpand),callback:function ($$v) {_vm.singleExpand=$$v},expression:"singleExpand"}})],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"background-color":"#CCCCCC"},attrs:{"colspan":headers.length}},[_c('list-presentation',{attrs:{"network":_vm.network,"groupID":item.ID}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }